import React, {useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {MEDIA_HOST} from "../common/constants";
import {useIndex, useGet, useMediaQuery} from '../common/hook';
import {postAuditEntry} from "../common/common";
import {getSessionCookie} from "../common/session";
import Title from "../components/title";
import Loader from "../components/loader";
import MetaTag from "../components/metatag";
import StayConnected from "../components/stayconnected";
import {ABOUT_US} from "../common/data";
import '../../scss/pages/aboutus.scss';

const pagetitle = 'About Us'
const source = 'about-us';

function AboutUs(props) {
    const index = useIndex(window.location.hostname, window.location.protocol);
    const [data, loading] = [ABOUT_US, false];
    const ddhomeCountry = getSessionCookie('ddhomeCountry');

    const matches = useMediaQuery('screen and (max-width: 820px)');

    useEffect(() => {
        postAuditEntry(
            {
                date: new Date(),
                countryCode: ddhomeCountry.country_code,
                hostName: window.location.hostname,
                ipAddress: ddhomeCountry.ip_address,
                page: 'about us',
                message: 'About Us Page Accessed'
            }
        );
    }, []);

    return (
        <>
            <MetaTag page={source} index={index} url={window.location.protocol + '//'  + window.location.hostname} />
            <div className="boxouter">
                <div className="container">
                    <div className="aboutusframe">
                        <Title message={pagetitle} />
                        <div className="storycontainer">
                            {loading ? (
                                <Loader loading={loading} />
                            ) : (
                                <>
                                    {data.map((item, i) => (
                                        <Story key={i} story={item} />
                                    ))}
                                    <StayConnected />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function Story({story}) {
    let storyClass;
    let route = '';
    if(story.key === 'Title') {
        storyClass = 'storytitle';
    } else if (story.key.indexOf("Route") > -1) {
        route = story.key.substring(6, story.key.length);
        storyClass = "storydescription";
    } else {
        storyClass = 'storydescription';
    }

    let routerMessage;
    let startmessage = '';
    let endmessage = '';
    if(route !== '') {
        let tempMessage = story.description;
        let linkStart = tempMessage.indexOf('<link>');
        let linkEnd = tempMessage.indexOf('</link>');
        let routeMessage = tempMessage.substring(linkStart + 6, linkEnd);
        if(linkStart> 0) {
            startmessage = tempMessage.substring(0, linkStart);
        }
        if(linkEnd + 8 < tempMessage.length) {
            endmessage = tempMessage.substring(linkEnd + 7, tempMessage.length)
        }
        routerMessage =
            <NavLink to={route}>{routeMessage}</NavLink>;
    } else {
        routerMessage = story.description;
    }

    let message =
        <>
            {startmessage}
            {routerMessage}
            {endmessage}
        </>

    return (
        <div className="story">
            <p>
                <label className={storyClass}>
                    {message}
                </label>
            </p>
        </div>
    )
}

export default AboutUs;
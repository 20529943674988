import React, {useState, useEffect, useRef, useCallback} from 'react';
import {postAuditEntry, s3Upload} from "../common/common";
import {onError} from "../common/error";
import Title from "../components/title";
import Enquiry from "../components/enquiry";
import {API, Auth} from 'aws-amplify';
import {AWS_CONFIG} from "../common/constants";
import MetaTag from "../components/metatag";
import {useIndex} from "../common/hook";
import {getSessionCookie} from "../common/session";
import FileUpload from "../components/fileupload";
import Button from "../components/button";
import {useHistory} from "react-router-dom";
import LoaderButton from "../components/loaderbutton";
import Webcam from "react-webcam";
import '../../scss/pages/serviceproduct.scss';
import '../../scss/pages/mortgageservice.scss';

const pagetitle = 'Mortgage Application - Biometric Check';
const source = 'mortgage-service';
const MAX_IMAGE_SIZE = 5000000;

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
};

function MortgageService(props) {
    const history = useHistory();
    const index = useIndex(window.location.hostname, window.location.protocol);
    const ddhomeCountry = getSessionCookie('ddhomeCountry');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [image, setImage] = useState('');

    useEffect(() => {
        postAuditEntry(
            {
                date: new Date(),
                countryCode: ddhomeCountry.country_code,
                hostName: window.location.hostname,
                ipAddress: ddhomeCountry.ip_address,
                page: 'mortgage service',
                message: 'Mortgage Service Page Accessed'
            }
        );
    }, []);

    const [newIdSet, setNewIdSet] = useState({
        idImages: []
    });

    const updateUploadedFiles = (files) => {
        setNewIdSet({ ...newIdSet, idImages: files });
    }

    useEffect(() => {
        if(newIdSet.idImages.length > 0) {
            let reader = new FileReader();
            reader.onload = (e) => {
                console.log('length: ', e.target.result.includes('data:image/jpeg'));
                if (!e.target.result.includes('data:image/jpeg') && !e.target.result.includes('data:image/png')) {
                    return alert('Wrong file type - JPG/PNG only.');
                }
                if (e.target.result.length > MAX_IMAGE_SIZE) {
                    return alert('Image is loo large.');
                }
                setImage(e.target.result);
            }
            reader.readAsDataURL(newIdSet.idImages[0]);
        }
    }, [newIdSet]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        //logic to create new user...
        setIsLoading(true);
        setMessage('');

        try {
            //console.log(newIdSet);
            /*const init = {
                response: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Encoding': 'base64',
                    'Content-Type': 'image/jpeg',
                },
            }*/
            //console.log(AWS_CONFIG.apiGateway.IMAGE + '/getIDUploadURLs/?sourceBucket=' + AWS_CONFIG.s3.DOCUMENT_BUCKET + '&sourceObject=' + newIdSet.idImages[0].name + '&targetBucket=' + AWS_CONFIG.s3.MEDIA_BUCKET + '&targetObject=' + 'customer-photo.jpg');
            //const formData = new FormData();
            //formData.append('File', newIdSet.idImages[0]);


/*            const response = await API.get('getIDUploadURLs',
                '/getIDUploadURLs?sourceBucket=' + AWS_CONFIG.s3.DOCUMENT_BUCKET + '&sourceObject=' + newIdSet.idImages[0].name + '&targetBucket=' + AWS_CONFIG.s3.MEDIA_BUCKET + '&targetObject=' + 'customer-photo.jpg',
                init);

            const uploadEndpoints = response.data;
            console.log(uploadEndpoints);*/
            /*let reader = new FileReader();
            reader.onload = (e) => {
                console.log('length: ', e.target.result.includes('data:image/jpeg'));
                if (!e.target.result.includes('data:image/jpeg') && !e.target.result.includes('data:image/png')) {
                    return alert('Wrong file type - JPG/PNG only.');
                }
                if (e.target.result.length > MAX_IMAGE_SIZE) {
                    return alert('Image is loo large.');
                }
                setImage(e.target.result);
            }
            reader.readAsDataURL(newIdSet.idImages[0]);*/
            //console.log(image);
            /*let token;
            Auth.currentSession()
                .then(res => {
                    token = res.getAccessToken()
                    //let jwt = accessToken.getJwtToken()
                    //You can print them to see the full objects
                    //console.log(`myJwt: ${jwt}`)
                })
            console.log(`Token: ${JSON.stringify(token)}`)*/
            /*let binary = atob(image.split(',')[1])
            let array = []
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i))
            }
            let blobIdData = new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
            console.log(blobIdData);*/
            //console.log(imgSrc);
            /*const result = await fetch(uploadEndpoints.sourceUploadURL, {
                method: 'POST',
                body: blobIdData,
                /!*headers: {
                    Authorization: `Bearer ${token}`
                }*!/
            });*/
            /*const result = await API.post(
                'uploadObjects',
                '/uploadObjects',
                {
                    response: true,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: {
                        sourceBucket: AWS_CONFIG.s3.DOCUMENT_BUCKET,
                        sourceObject: newIdSet.idImages[0].name,
                        sourceBody: image,
                        targetBucket: AWS_CONFIG.s3.MEDIA_BUCKET,
                        targetObject: 'customer-photo.jpg',
                        targetBody: imgSrc
                    }
                }
            )
            console.log('Result: ', result);*/

            await API.post(
            'validateIDWithPhoto',
            '/validateIDWithPhoto',
            {
                    response: true,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: {
                        sourceBucket: AWS_CONFIG.s3.DOCUMENT_BUCKET,
                        sourceObject: newIdSet.idImages[0].name,
                        sourceBody: image,
                        targetBucket: AWS_CONFIG.s3.MEDIA_BUCKET,
                        targetObject: 'customer-photo.jpg',
                        targetBody: imgSrc
                    }
                }
            )
            .then(async response => {
                console.log('Result: ', response);
                return await response.data
            })
            .then(data => {
                const compare = data.compareFaces;
                if(compare === 1) {
                    setMessage('Your ID and Photo Validation has been successful. Please proceed to next step')
                    setSuccess(true);
                } else {
                    setMessage('Your ID and Photo Validation has not been successful');
                    setSuccess(false);
                }
            });
            setIsLoading(false);
        } catch (e) {
            onError(e);
            setMessage('Your ID and Photo Validation has not been successful');
            setSuccess(false);
            setIsLoading(false);
        }
    };

    const validateForm = () => {
        let valid = false;
        if(newIdSet.idImages.length > 0 && imgSrc) {
            valid = true;
        }
        return valid;
    }

    const removeImages = () => {
        setNewIdSet({...newIdSet, idImages: []});
    }

    const [imgSrc, setImgSrc] = React.useState(null);

    const webcamRef = useRef(null);

    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setImgSrc(imageSrc);
        },
        [webcamRef, setImgSrc]
    );

    const nextStep = (event) => {
        event.preventDefault();
        history.push('/mortgage-application', {
            idName: newIdSet.idImages[0].name,
            idLocation: AWS_CONFIG.s3.DOCUMENT_BUCKET,
            photoName: 'customer-photo.jpg',
            photoLocation: AWS_CONFIG.s3.MEDIA_BUCKET
        });
    }

    return (
        <>
            <MetaTag page={source} index={index} url={window.location.protocol + '//'  + window.location.hostname} />
            <div className="boxouter">
                <div className="container" style={{width: '100%'}}>
                    <div className="serveasepageframe">
                        <Title message={pagetitle} />
                        <div className="contactuscontainer">
                            <form onSubmit={handleSubmit}>
                                <div className="fieldcontainer">
                                    <p className="contactus" style={{fontSize: '1.1rem', color: 'rgb(34, 38, 41)', textAlign: 'left'}}>
                                        <label>
                                            <strong>Step 1:</strong> Capture your photograph showing full facial frame
                                        </label>
                                    </p>
                                </div>
                                <div className="fieldcontainer">
                                    <div className="selfiecontainer">
                                        <Webcam
                                            audio={false}
                                            mirrored={true}
                                            height={450}
                                            ref={webcamRef}
                                            screenshotFormat="image/jpeg"
                                            width={800}
                                            videoConstraints={videoConstraints}
                                        />
                                    </div>
                                    <div className="photocontainer">
                                        {imgSrc && (
                                            <img
                                                src={imgSrc} width="800px" height="450px"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="enquirybuttoncontainer">
                                    <Button name="CaptureImageButton"
                                            type="button"
                                            label="Capture Photo"
                                            onClick={capture}
                                            disabled={success}
                                    />
                                </div>
                                <div className="fieldcontainer">
                                    <p className="contactus" style={{fontSize: '1.1rem', color: 'rgb(34, 38, 41)', textAlign: 'left'}}>
                                        <label>
                                            <strong>Step 2:</strong> Upload or drag & drop your ID document (only .jpg or .png)
                                        </label>
                                    </p>
                                </div>
                                <FileUpload
                                    accept=".jpg,.png,.jpeg"
                                    label=""
                                    multiple={false}
                                    updateFilesCb={updateUploadedFiles}
                                    disabled={success}
                                />
                                <div className="fieldcontainer" style={{textAlign: "center"}}>
                                    <label style={{fontSize: '1.1rem', color: success ? 'green' : 'red', fontWeight: 'bold'}}>{message}</label>
                                </div>
                                <div className="enquirybuttoncontainer">
                                    <LoaderButton name="SubmitIdCheckButton"
                                                  type="submit"
                                                  label="Validate ID"
                                                  isLoading={isLoading}
                                                  disabled={!validateForm() || success}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {success &&
                                        <LoaderButton name="NextStepButton"
                                                      type="button"
                                                      label="Next Step"
                                                      isLoading={isLoading}
                                                      onClick={nextStep}
                                        />
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MortgageService;
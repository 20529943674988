import React from "react";
import '../../scss/common/icon.scss';

function Icon(props) {
    let icon;
    let style={};
    if(props.width) {
        style.width = props.width;
    }
    if(props.height) {
        style.height = props.height;
    }
    if(props.verticalAlign) {
        style.vertifcalAlign = props.vertifcalAlign;
    }
    switch(props.name) {
        case 'about':
            icon =
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412.053 412.053" style={{...style}}>
                    <path id="XMLID_173_" d="M396.399,67.735c-2.731-14.978-9.574-28.905-19.791-40.284c-0.02-0.022-0.039-0.045-0.059-0.067
                        c-5.233-5.909-13.905-14.033-25.497-19.181l-0.264-0.117C339.6,2.72,327.594,0,315.105,0c-18.47,0-35.94,5.962-50.522,17.243
                        c-0.014,0.011-0.08,0.063-0.098,0.076c-12.536,9.789-20.809,23.777-21.833,25.559h0c-0.046,0.078-0.091,0.157-0.134,0.236
                        c-6.577,12.058-10.053,25.726-10.053,39.527c0,2.866,0.15,5.767,0.448,8.621c0.277,2.667,1.873,5.017,4.248,6.26
                        c1.504,0.787,3.076,1.442,4.691,1.96c6.438,19.706,20.499,35.466,38.375,44.329c-7.663,3.914-14.545,9.089-20.498,15.418
                        c-3.027,3.218-2.872,8.281,0.347,11.308c3.218,3.026,8.281,2.871,11.308-0.347c2.861-3.042,5.992-5.744,9.369-8.088
                        c4.843,13.721,17.325,23.773,31.522,23.773h1.194c14.221,0,26.691-10.773,31.248-25.35c16.893,10.59,27.701,29.458,27.701,49.641
                        v83.099l-72.764,47.215c-3.707,2.405-4.762,7.359-2.356,11.065c1.532,2.361,4.099,3.646,6.718,3.646c1.493,0,3.002-0.417,4.347-1.29
                        l76.409-49.58c2.273-1.475,3.646-4.001,3.646-6.711v-87.444c0-28.331-15.816-53.725-40.812-66.396
                        c23.671-11.814,40.41-35.658,42.05-63.429c2.258-2.047,4.221-4.452,5.791-7.157C396.404,71.54,396.741,69.607,396.399,67.735z
                         M313.47,169.876h-1.194c-9.574,0-17.663-9.28-17.663-20.265c0-0.137-0.003-0.273-0.01-0.409c6.203,1.631,12.691,2.494,19.344,2.494
                        c5.686,0,11.235-0.634,16.572-1.835C329.893,160.677,322.195,169.876,313.47,169.876z M313.946,135.696
                        c-24.123,0-45.512-14.37-54.882-35.91c11.108-3.142,19.605-12.566,21.397-24.173c4.773,0.613,9.61,0.922,14.463,0.922
                        c16.272,0,31.948-3.411,45.987-9.943c3.618,12.425,15.11,21.531,28.688,21.531c0.99,0,1.974-0.05,2.949-0.148
                        C366.907,115.155,342.701,135.696,313.946,135.696z M369.599,72.124c-7.649,0-13.872-6.221-13.872-13.869v-4.452
                        c0-2.911-1.581-5.592-4.129-7.001c-2.549-1.41-5.661-1.323-8.125,0.226c-14.077,8.837-30.865,13.508-48.55,13.508
                        c-6.916,0-13.786-0.729-20.419-2.167c-2.363-0.513-4.833,0.072-6.717,1.591c-1.883,1.519-2.978,3.808-2.978,6.227v4.853
                        c0,7.647-6.222,13.869-13.87,13.869c-0.524,0-1.042-0.029-1.554-0.086c-0.119-0.018-0.238-0.033-0.357-0.045
                        c-0.179-0.024-0.356-0.053-0.533-0.084c-0.021-0.686-0.032-1.37-0.032-2.052c0-11.1,2.785-22.088,8.055-31.783
                        c0.021-0.039,0.043-0.077,0.06-0.106c0.068-0.123,7.294-12.65,17.741-20.813c0.021-0.017,0.048-0.037,0.053-0.041
                        C286.126,20.806,300.211,16,315.105,16c10.075,0,19.753,2.191,28.764,6.512c0.156,0.075,0.387,0.18,0.689,0.313
                        c8.897,3.952,15.728,10.348,19.918,15.059c0.046,0.054,0.093,0.107,0.141,0.161c7.55,8.377,12.826,18.469,15.374,29.354
                        C377.374,70.363,373.59,72.124,369.599,72.124z M245.81,276.808c18.128-10.667,30.322-30.386,30.322-52.897
                        c0-10.13-2.47-19.696-6.837-28.125c-0.157-0.354-0.336-0.691-0.536-1.011c-10.386-19.154-30.676-32.191-53.954-32.191
                        c-23.283,0-43.577,13.041-53.96,32.204c-0.194,0.312-0.369,0.641-0.522,0.985c-4.373,8.432-6.844,18.002-6.844,28.138
                        c0,22.507,12.188,42.222,30.311,52.89c-22.433,11.597-36.643,34.617-36.643,60.093v29.178c0,2.735,1.397,5.28,3.704,6.749
                        l59.659,37.98c1.311,0.834,2.803,1.251,4.296,1.251c1.484,0,2.968-0.412,4.273-1.237l59.8-37.781
                        c2.332-1.473,3.74-4.043,3.727-6.801l-0.141-29.377C282.343,311.32,268.148,288.376,245.81,276.808z M214.805,178.584
                        c13.925,0,26.401,6.31,34.723,16.222c-10.78,3.089-22.602,4.7-34.721,4.7c-12.123,0-23.946-1.611-34.725-4.7
                        C188.404,184.894,200.881,178.584,214.805,178.584z M169.478,223.911c0-5.197,0.879-10.194,2.497-14.847
                        c13.201,4.228,27.85,6.442,42.832,6.442c14.978,0,29.625-2.214,42.828-6.442c1.618,4.653,2.497,9.65,2.497,14.847
                        c0,24.993-20.333,45.327-45.327,45.327C189.812,269.238,169.478,248.904,169.478,223.911z M229.005,283.579
                        c-1,7.568-7.638,12.684-13.644,12.684h-0.974c-6.361,0-13.425-5.38-14.34-12.82c4.728,1.172,9.672,1.795,14.757,1.795
                        C219.69,285.238,224.446,284.663,229.005,283.579z M214.822,394.58l-51.676-32.898v-24.787c0-17.591,8.888-33.653,23.275-43.119
                        c4.999,11.407,16.783,18.487,27.966,18.487h0.974c12.073,0,22.745-7.857,27.355-18.773c14.595,9.41,23.663,25.598,23.748,43.443
                        l0.12,24.944L214.822,394.58z M157.359,179.842c2.332-3.753,1.18-8.686-2.572-11.018c-2.436-1.514-4.962-2.887-7.554-4.109
                        c9.886-5.054,18.631-12.293,25.573-21.348c2.859,0.992,5.928,1.532,9.12,1.532c15.373,0,27.88-12.507,27.88-27.88V97.138
                        c0-25.272-9.654-49.207-27.183-67.394C165.144,11.61,141.678,1.076,116.548,0.082c-0.002,0-0.005,0-0.007,0
                        c-2.184-0.084-4.217-0.104-6.216-0.055c-25.018,0.604-48.617,10.73-66.45,28.512c-18.283,18.23-28.352,42.593-28.352,68.6v19.881
                        c0,15.373,12.507,27.88,27.881,27.88c3.192,0,6.26-0.539,9.118-1.531c7.647,9.974,17.483,17.745,28.622,22.819
                        c-24.685,12.982-39.953,38.112-39.953,65.94v65.484c0,2.71,1.372,5.236,3.646,6.711l72.575,47.091
                        c1.345,0.873,2.854,1.29,4.347,1.29c2.619,0,5.187-1.286,6.718-3.646c2.405-3.707,1.35-8.661-2.356-11.065l-68.93-44.726v-61.139
                        c0-18.51,8.619-35.505,23.058-46.472c4.952,14.37,17.668,25.167,31.728,25.167h1.194c15.27,0,27.458-13.822,31.75-29.264
                        c0.477,0.278,0.95,0.563,1.419,0.854C150.094,184.747,155.027,183.594,157.359,179.842z M63.19,68.063
                        c-5.172,8.784-7.906,18.838-7.906,29.076v19.881c0,3.79-1.784,7.171-4.557,9.348c-0.238,0.155-0.465,0.321-0.681,0.497
                        c-1.897,1.285-4.184,2.035-6.643,2.035c-6.551,0-11.88-5.329-11.88-11.88V97.138c0-44.413,34.784-80.043,79.185-81.117
                        c1.66-0.041,3.365-0.023,5.213,0.048c43.673,1.731,77.884,37.34,77.884,81.069v19.881c0,6.551-5.33,11.88-11.881,11.88
                        c-2.459,0-4.747-0.751-6.645-2.036c-0.215-0.175-0.441-0.34-0.678-0.495c-2.773-2.177-4.558-5.559-4.558-9.349V97.138
                        c0-28.238-21.036-52.64-48.932-56.761c-2.193-0.326-4.422,0.276-6.155,1.658c-1.733,1.382-2.816,3.42-2.99,5.63
                        c-0.439,5.592-1.453,11.106-3.022,16.457H70.083C67.25,64.122,64.627,65.621,63.19,68.063z M113.171,194.823h-1.194
                        c-9.044,0-17.921-10.979-17.921-22.166c0-0.69-0.089-1.369-0.259-2.02c6.076,1.545,12.403,2.35,18.868,2.35
                        c6.12,0,12.118-0.722,17.895-2.109C130.016,182.511,121.212,194.823,113.171,194.823z M112.665,156.987
                        c-18.646,0-35.855-8.489-47.161-22.99c3.622-4.705,5.779-10.595,5.779-16.978V97.138c0-5.893,1.254-11.698,3.652-17.016h39.783
                        c3.349,0,6.343-2.085,7.504-5.227c2-5.41,3.509-10.991,4.513-16.675c16.017,5.83,27.309,21.343,27.309,38.918v19.881
                        c0,6.383,2.157,12.273,5.78,16.978C148.519,148.499,131.311,156.987,112.665,156.987z" fill={props.fill} />
                </svg>
            break;
        case 'contact':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{...style}}>
                    <g>
                        <g>
                            <g>
                                <path d="M3.217,126.14L224.87,302.686c1.579,1.331,15.821,13.047,31.13,13.047c15.377,0,29.747-11.281,31.292-12.535
                                    l221.5-177.067c2.031-1.621,3.208-4.07,3.208-6.664C512,95.94,492.86,76.8,469.333,76.8H42.667C19.14,76.8,0,95.94,0,119.467
                                    C0,122.069,1.186,124.518,3.217,126.14z M42.667,93.867h426.667c12.8,0,23.441,9.446,25.31,21.726L276.599,289.894
                                    c-3.038,2.44-12.672,8.772-20.599,8.772c-7.689,0-17.109-6.502-20.284-9.165L17.357,115.584
                                    C19.234,103.305,29.867,93.867,42.667,93.867z" fill={props.fill} />
                                <path d="M156.86,274.893L37.393,368.759c-3.703,2.91-4.343,8.277-1.434,11.981c1.681,2.142,4.181,3.26,6.707,3.26
                                    c1.852,0,3.712-0.597,5.274-1.826l119.467-93.867c3.703-2.91,4.344-8.277,1.434-11.981
                                    C165.931,272.614,160.563,271.983,156.86,274.893z" fill={props.fill} />
                                <path d="M355.14,274.893c-3.703-2.91-9.071-2.27-11.981,1.434s-2.27,9.071,1.434,11.981l119.467,93.867
				                    c1.562,1.229,3.422,1.826,5.265,1.826c2.526,0,5.035-1.118,6.716-3.26c2.91-3.703,2.27-9.071-1.434-11.981L355.14,274.893z" fill={props.fill} />
                                <path d="M503.467,153.6c-4.71,0-8.533,3.823-8.533,8.533v230.4c0,14.114-11.486,25.6-25.6,25.6H42.667
                                    c-14.114,0-25.6-11.486-25.6-25.6v-230.4c0-4.71-3.823-8.533-8.533-8.533S0,157.423,0,162.133v230.4
                                    C0,416.06,19.14,435.2,42.667,435.2h426.667c23.526,0,42.667-19.14,42.667-42.667v-230.4
                                    C512,157.423,508.177,153.6,503.467,153.6z" fill={props.fill} />
                            </g>
                        </g>
                    </g>
                </svg>
            break;
        case 'blog':
            icon =
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{...style}}>
                    <g>
                        <path d="M437.022,74.984C388.67,26.63,324.381,0,256,0C187.624,0,123.338,26.63,74.984,74.984S0,187.624,0,256
                            c0,68.388,26.63,132.678,74.984,181.028C123.335,485.375,187.621,512,256,512c68.385,0,132.673-26.625,181.021-74.972
                            C485.372,388.679,512,324.389,512,256C512,187.623,485.372,123.336,437.022,74.984z M425.708,425.714
                            C380.381,471.039,320.111,496,256,496c-64.106,0-124.374-24.961-169.703-70.286C40.965,380.386,16,320.113,16,256
                            c0-64.102,24.965-124.37,70.297-169.703C131.63,40.965,191.898,16,256,16c64.108,0,124.378,24.965,169.708,70.297
                            C471.037,131.628,496,191.896,496,256C496,320.115,471.037,380.387,425.708,425.714z" fill={props.fill} />
                        <path d="M430.038,114.969c-2.784-3.432-7.821-3.957-11.253-1.172c-3.431,2.784-3.956,7.822-1.172,11.252
                            C447.526,161.919,464,208.426,464,256c0,55.567-21.635,107.803-60.919,147.085C363.797,442.367,311.563,464,256,464
                            c-51.26,0-100.505-18.807-138.663-52.956c-3.292-2.946-8.35-2.665-11.296,0.626c-2.946,3.292-2.666,8.35,0.626,11.296
                            C147.763,459.745,200.797,480,256,480c59.837,0,116.089-23.297,158.394-65.601C456.701,372.095,480,315.84,480,256
                            C480,204.767,462.256,154.681,430.038,114.969z" fill={props.fill} />
                        <path d="M48,256c0-114.691,93.309-208,208-208c51.26,0,100.504,18.808,138.662,52.959c3.293,2.948,8.351,2.667,11.296-0.625
                            c2.947-3.292,2.667-8.35-0.625-11.296C364.237,52.256,311.203,32,256,32c-59.829,0-116.08,23.301-158.389,65.611
                            C55.301,139.921,32,196.171,32,256c0,51.239,17.744,101.328,49.963,141.038c1.581,1.949,3.889,2.96,6.217,2.96
                            c1.771,0,3.553-0.585,5.036-1.788c3.431-2.784,3.956-7.822,1.172-11.253C64.474,350.088,48,303.58,48,256z" fill={props.fill} />
                        <path d="M378.664,233.281l-1.911-3.974c-0.539-1.12-1.331-2.1-2.314-2.86l-3.178-2.458c-3.943-3.094-9.624-3.229-17.486-3.415
                            c-3.936-0.093-11.188-0.265-13.305-1.31c-1.529-1.694-2.241-7.044-2.993-12.698l-0.23-1.718
                            c-2.031-15.696-3.585-19.434-6.155-24.69c-0.309-0.631-0.636-1.298-1.012-2.111c-11.254-23.815-40.932-41.236-61.975-43.718
                            c-0.311-0.037-0.624-0.055-0.937-0.055h-50.41c-44.162,0-80.09,35.829-80.09,79.869v83.839c0,43.972,35.928,79.746,80.09,79.746
                            h82.815c43.922,0,79.856-35.754,80.103-79.685l0.445-58.073c0.005-0.576-0.053-1.15-0.172-1.713l-0.667-3.159
                            C379.149,234.469,378.942,233.86,378.664,233.281z M363.675,297.936c-0.197,35.174-28.954,63.791-64.103,63.791h-82.815
                            c-35.339,0-64.09-28.596-64.09-63.746v-83.839c0-35.218,28.751-63.869,64.09-63.869h49.916c16.52,2.157,40.51,16.771,48.912,34.55
                            c0.402,0.869,0.777,1.637,1.133,2.364c1.789,3.657,2.868,5.865,4.667,19.757l0.232,1.732c1.223,9.188,2.278,17.124,8.232,22.571
                            c5.393,4.892,14.622,5.11,23.547,5.321c2.826,0.067,6.983,0.166,8.65,0.52l1.107,0.856l0.673,1.4l0.289,1.367L363.675,297.936z" fill={props.fill} />
                        <path d="M217.67,236.456h39.972c12.025,0,21.808-9.755,21.808-21.746c0-11.978-9.783-21.723-21.808-21.723H217.67
                            c-12.019,0-21.796,9.745-21.796,21.723C195.874,226.701,205.651,236.456,217.67,236.456z M217.67,208.988h39.972
                            c3.203,0,5.808,2.567,5.808,5.723c0,3.114-2.66,5.746-5.808,5.746H217.67c-3.142,0-5.796-2.631-5.796-5.746
                            C211.874,211.555,214.474,208.988,217.67,208.988z" fill={props.fill} />
                        <path d="M298.881,275.247H217.67c-12.019,0-21.796,9.722-21.796,21.672c0,11.951,9.777,21.673,21.796,21.673h81.211
                            c11.985,0,21.735-9.722,21.735-21.673C320.616,284.969,310.866,275.247,298.881,275.247z M298.881,302.592H217.67
                            c-3.142,0-5.796-2.598-5.796-5.673c0-3.128,2.6-5.672,5.796-5.672h81.211c3.163,0,5.735,2.544,5.735,5.672
                            C304.616,299.995,301.99,302.592,298.881,302.592z" fill={props.fill} />
                    </g>
                </svg>
            break;
        case 'gallery':
            icon =
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 455 455" style={{...style}}>
                    <g>
                        <path d="M417.5,120H313.355l-11.303-45.215C297.176,55.28,277.606,40,257.5,40h-60c-20.106,0-39.676,15.28-44.553,34.785
                            L141.645,120H130V97.5c0-4.142-3.357-7.5-7.5-7.5h-60c-4.143,0-7.5,3.358-7.5,7.5V120H37.5C16.822,120,0,136.823,0,157.5v220
                            C0,398.177,16.822,415,37.5,415h380c20.678,0,37.5-16.823,37.5-37.5v-220C455,136.823,438.178,120,417.5,120z M70,105h45v15H70V105
                            z M37.5,135h110c3.441,0,6.441-2.342,7.276-5.681L167.5,78.423C170.674,65.727,184.412,55,197.5,55h60
                            c13.088,0,26.826,10.727,30,23.423l12.724,50.896c0.835,3.339,3.835,5.681,7.276,5.681h110c12.406,0,22.5,10.094,22.5,22.5V180
                            H305.832c-20.803-18.643-48.264-30-78.332-30s-57.528,11.357-78.332,30H15v-22.5C15,145.094,25.094,135,37.5,135z M227.5,370
                            C170.981,370,125,324.019,125,267.5S170.981,165,227.5,165S330,210.981,330,267.5S284.019,370,227.5,370z M15,195h120.094
                            c-10.27,13.061-17.827,28.347-21.836,45H99.315c-15.163,0-27.5,12.336-27.5,27.5s12.337,27.5,27.5,27.5h13.942
                            c4.009,16.653,11.566,31.939,21.836,45H15V195z M110.666,280h-11.35c-6.893,0-12.5-5.607-12.5-12.5s5.607-12.5,12.5-12.5h11.35
                            c-0.437,4.108-0.666,8.278-0.666,12.5S110.229,275.892,110.666,280z M417.5,400h-380C25.094,400,15,389.906,15,377.5V355h134.168
                            c20.803,18.643,48.264,30,78.332,30s57.528-11.357,78.332-30H357.5c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-37.594
                            C335.619,320.017,345,294.834,345,267.5s-9.381-52.517-25.094-72.5H440v145h-52.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5
                            H440v22.5C440,389.906,429.906,400,417.5,400z" fill={props.fill} />
                        <path d="M178.424,135h98.152c2.31,0,4.49-1.064,5.911-2.884c1.422-1.82,1.925-4.194,1.365-6.435l-12.499-50
                            c-0.835-3.339-3.835-5.681-7.276-5.681h-73.154c-3.441,0-6.441,2.342-7.276,5.681l-12.499,50c-0.56,2.24-0.057,4.614,1.365,6.435
                            C173.934,133.936,176.114,135,178.424,135z M196.778,85h61.443l8.749,35h-78.941L196.778,85z" fill={props.fill} />
                        <path d="M227.5,190c-42.733,0-77.5,34.766-77.5,77.5s34.767,77.5,77.5,77.5s77.5-34.766,77.5-77.5S270.233,190,227.5,190z
		                    M227.5,330c-34.463,0-62.5-28.037-62.5-62.5s28.037-62.5,62.5-62.5s62.5,28.037,62.5,62.5S261.963,330,227.5,330z" fill={props.fill} />
                        <path d="M271.229,248.975c-1.613-3.817-6.017-5.602-9.827-3.991c-3.816,1.612-5.603,6.011-3.991,9.827
                            c5.185,12.274,2.465,26.275-6.93,35.669c-6.126,6.126-14.287,9.5-22.98,9.5s-16.854-3.374-22.98-9.5
                            c-2.93-2.929-7.678-2.929-10.607,0c-2.929,2.929-2.929,7.677,0,10.606c8.96,8.959,20.888,13.894,33.588,13.894
                            c12.699,0,24.629-4.935,33.588-13.894C274.819,287.356,278.8,266.901,271.229,248.975z" fill={props.fill} />
                        <path d="M199.216,246.716c1.919,0,3.839-0.732,5.304-2.197c9.393-9.393,23.392-12.114,35.669-6.929
                            c3.814,1.61,8.216-0.175,9.827-3.991c1.611-3.816-0.176-8.216-3.991-9.827c-17.927-7.57-38.38-3.591-52.112,10.141
                            c-2.929,2.929-2.929,7.678,0,10.607C195.377,245.984,197.297,246.716,199.216,246.716z" fill={props.fill} />
                    </g>
                </svg>
            break;
        case 'links':
            icon =
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58" style={{...style}}>
                    <path d="M50.688,48.222C55.232,43.101,58,36.369,58,29c0-7.667-2.996-14.643-7.872-19.834c0,0,0-0.001,0-0.001
                        c-0.004-0.006-0.01-0.008-0.013-0.013c-5.079-5.399-12.195-8.855-20.11-9.126l-0.001-0.001L29.439,0.01C29.293,0.005,29.147,0,29,0
                        s-0.293,0.005-0.439,0.01l-0.563,0.015l-0.001,0.001c-7.915,0.271-15.031,3.727-20.11,9.126c-0.004,0.005-0.01,0.007-0.013,0.013
                        c0,0,0,0.001-0.001,0.002C2.996,14.357,0,21.333,0,29c0,7.369,2.768,14.101,7.312,19.222c0.006,0.009,0.006,0.019,0.013,0.028
                        c0.018,0.025,0.044,0.037,0.063,0.06c5.106,5.708,12.432,9.385,20.608,9.665l0.001,0.001l0.563,0.015C28.707,57.995,28.853,58,29,58
                        s0.293-0.005,0.439-0.01l0.563-0.015l0.001-0.001c8.185-0.281,15.519-3.965,20.625-9.685c0.013-0.017,0.034-0.022,0.046-0.04
                        C50.682,48.241,50.682,48.231,50.688,48.222z M2.025,30h12.003c0.113,4.239,0.941,8.358,2.415,12.217
                        c-2.844,1.029-5.563,2.409-8.111,4.131C4.585,41.891,2.253,36.21,2.025,30z M8.878,11.023c2.488,1.618,5.137,2.914,7.9,3.882
                        C15.086,19.012,14.15,23.44,14.028,28H2.025C2.264,21.493,4.812,15.568,8.878,11.023z M55.975,28H43.972
                        c-0.122-4.56-1.058-8.988-2.75-13.095c2.763-0.968,5.412-2.264,7.9-3.882C53.188,15.568,55.736,21.493,55.975,28z M28,14.963
                        c-2.891-0.082-5.729-0.513-8.471-1.283C21.556,9.522,24.418,5.769,28,2.644V14.963z M28,16.963V28H16.028
                        c0.123-4.348,1.035-8.565,2.666-12.475C21.7,16.396,24.821,16.878,28,16.963z M30,16.963c3.179-0.085,6.3-0.566,9.307-1.438
                        c1.631,3.91,2.543,8.127,2.666,12.475H30V16.963z M30,14.963V2.644c3.582,3.125,6.444,6.878,8.471,11.036
                        C35.729,14.45,32.891,14.881,30,14.963z M40.409,13.072c-1.921-4.025-4.587-7.692-7.888-10.835
                        c5.856,0.766,11.125,3.414,15.183,7.318C45.4,11.017,42.956,12.193,40.409,13.072z M17.591,13.072
                        c-2.547-0.879-4.991-2.055-7.294-3.517c4.057-3.904,9.327-6.552,15.183-7.318C22.178,5.38,19.512,9.047,17.591,13.072z M16.028,30
                        H28v10.038c-3.307,0.088-6.547,0.604-9.661,1.541C16.932,37.924,16.141,34.019,16.028,30z M28,42.038v13.318
                        c-3.834-3.345-6.84-7.409-8.884-11.917C21.983,42.594,24.961,42.124,28,42.038z M30,55.356V42.038
                        c3.039,0.085,6.017,0.556,8.884,1.4C36.84,47.947,33.834,52.011,30,55.356z M30,40.038V30h11.972
                        c-0.113,4.019-0.904,7.924-2.312,11.58C36.547,40.642,33.307,40.126,30,40.038z M43.972,30h12.003
                        c-0.228,6.21-2.559,11.891-6.307,16.348c-2.548-1.722-5.267-3.102-8.111-4.131C43.032,38.358,43.859,34.239,43.972,30z
                         M9.691,47.846c2.366-1.572,4.885-2.836,7.517-3.781c1.945,4.36,4.737,8.333,8.271,11.698C19.328,54.958,13.823,52.078,9.691,47.846
                        z M32.521,55.763c3.534-3.364,6.326-7.337,8.271-11.698c2.632,0.945,5.15,2.209,7.517,3.781
                        C44.177,52.078,38.672,54.958,32.521,55.763z" fill={props.fill} />
                </svg>
            break;
        case 'user':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{...style}}>
                    <g>
                        <g>
                            <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256c2.581,0,5.099-0.32,7.68-0.384l0.896,0.171
                                c0.704,0.128,1.387,0.213,2.091,0.213c0.981,0,1.984-0.128,2.923-0.405l1.195-0.341C405.056,503.509,512,392.171,512,256
                                C512,114.837,397.163,0,256,0z M408.149,434.325c-1.003-3.264-3.264-6.016-6.549-7.104l-56.149-18.688
                                c-19.605-8.171-28.736-39.552-30.869-52.139c14.528-13.504,27.947-33.621,27.947-51.797c0-6.187,1.749-8.555,1.408-8.619
                                c3.328-0.832,6.037-3.2,7.317-6.379c1.045-2.624,10.24-26.069,10.24-41.877c0-0.875-0.107-1.749-0.32-2.581
                                c-1.344-5.355-4.48-10.752-9.173-14.123v-49.664c0-30.699-9.344-43.563-19.243-51.008c-2.219-15.275-18.581-44.992-76.757-44.992
                                c-59.477,0-96,55.915-96,96v49.664c-4.693,3.371-7.829,8.768-9.173,14.123c-0.213,0.853-0.32,1.728-0.32,2.581
                                c0,15.808,9.195,39.253,10.24,41.877c1.28,3.179,2.965,5.205,6.293,6.037c0.683,0.405,2.432,2.795,2.432,8.96
                                c0,18.176,13.419,38.293,27.947,51.797c-2.112,12.565-11.157,43.925-30.144,51.861l-56.896,18.965
                                c-3.264,1.088-5.611,3.776-6.635,7.04C53.376,391.189,21.291,327.317,21.291,256c0-129.387,105.28-234.667,234.667-234.667
                                S490.624,126.613,490.624,256C490.667,327.339,458.56,391.253,408.149,434.325z" fill={props.fill} />
                        </g>
                    </g>
                </svg>
            break;
        case 'news':
            icon =
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.537 64.536" style={{...style}}>
                    <g>
                        <path d="M11.051,16.944c-0.263-0.159-0.47-0.401-0.78-0.488c0.656-0.656,0.93-0.703,1.951-0.341
                            c0.238,0.867,0.423,1.417,1.03,1.719l-0.027,0.015c-0.795,0.301-1.355,1.13-2.344,1.013c0.136-0.355,0.396-0.669,0.409-1.077
                            l0.56-0.373c-0.065,0.004-0.143,0.004-0.217,0c-0.156-0.01-0.327-0.015-0.443,0.049c-0.005,0-0.41,0.341-0.79,0.698
                            c-0.133,0.119-0.28,0.259-0.682,0.446C9.83,18.047,9.997,17.568,11.051,16.944z M64.537,36.359c0,13.584-11.055,24.64-24.641,24.64
                            c-9.028,0-16.914-4.895-21.205-12.151C8.071,46.884,0,37.57,0,26.388C0,13.786,10.252,3.537,22.856,3.537
                            c7.019,0,13.274,3.207,17.468,8.203c4.813,0.086,9.494,1.58,13.445,4.275l3.786-3.787l3.048,11.679L48.92,20.864l2.652-2.651
                            c-3.467-2.225-7.521-3.45-11.675-3.45c-11.91,0-21.599,9.684-21.599,21.599c0,11.907,9.689,21.598,21.599,21.598
                            c11.914,0,21.598-9.69,21.598-21.598h3.041V36.359z M19.714,17.229c0.004,0.009,0.659,0.517,0.767,0.767l0.504-0.552l-1.287-0.224
                            L19.714,17.229z M33.769,12.52c0.165-0.042,0.337-0.087,0.502-0.128l-0.014-0.02l-0.08-0.105l-0.081-0.104
                            c-0.117,0.067-0.26,0.182-0.355,0.272C33.743,12.462,33.76,12.495,33.769,12.52z M35.886,12.072
                            c0.073-0.009,0.144-0.019,0.214-0.033c-0.098-0.096-0.224-0.228-0.381-0.397C35.695,11.78,35.706,11.84,35.886,12.072z
                             M36.662,10.406c0.465,0.872,0.625,1.201,0.685,1.445c0.26-0.027,0.529-0.023,0.791-0.041
                            C37.667,11.318,37.177,10.848,36.662,10.406z M36.107,11.064c-0.021-0.047-0.171-0.45-0.49-0.962l-0.027-0.037l0.005,0.032
                            c0.031,0.143,0.031,0.278,0.06,0.421l0.048,0.047C36.037,10.895,36.086,11.026,36.107,11.064z M34.893,9.051l0.596,0.809
                            c0.15,0.022,0.354,0.018,0.505-0.015C35.635,9.558,35.261,9.308,34.893,9.051z M32.354,7.814c0.925,1.043,1.023,1.153,1.5,1.994
                            c-0.134,0.59-0.134,0.59,1.01,1.488c0.057-0.038,0.132-0.091,0.189-0.133c-0.096-0.315-0.096-0.315-0.441-0.756l0.062,0.049
                            l0.106,0.1l0.109,0.105l0.105,0.101l0.102,0.1l0.1,0.101l0.096,0.1l0.101,0.101c0.055,0,0.142,0,0.201,0
                            c0.196-0.537,0.196-0.537-1.115-2.193l0.334,0.02C34.106,8.5,33.377,8.04,32.606,7.638C32.517,7.696,32.454,7.757,32.354,7.814z
                             M29.301,11.327c0.165,0.114,0.378,0.264,0.544,0.369c1.072-0.168,1.079-0.383,1.079-0.569v-0.078
                            c-0.324-0.046-0.591-0.038-0.927,0.023C29.7,11.135,29.497,11.2,29.301,11.327z M23.909,7.195
                            c-0.278,0.446-0.516,0.742-0.988,1.226c0.196,0.004,0.609,0.145,0.609,0.145c0.29,0.225,0.525,0.651,0.762,0.923
                            c0.028,0.183,0.011,0.325-0.054,0.602c0.105,0.134,0.252,0.297,0.357,0.435c-0.138,0.339-0.282,0.638-0.417,0.905l-0.07,0.127
                            c-0.035,0.393,0.252,0.695,0.296,1.059c0.346,0.082,0.53,0.474,0.933,0.465c0.306-0.009,0.296-0.255,0.261-0.512
                            c0.486-0.096,2.187-1.03,3.301-2.441c-0.182,0.014-0.694-0.245-0.708-0.383c0.096-0.096,0.183-0.119,0.792-0.096
                            c-0.179-0.25-0.362-0.493-0.549-0.726c0.105-0.009,0.216,0.015,0.477,0.096c-0.04-0.128-0.098-0.301-0.138-0.424
                            c-0.833-0.717-1.112-1.593-1.112-1.603l0.004-0.038c-0.413,0.339-0.446,0.368-0.649,0.245c-0.101-0.091-0.206-0.196-0.31-0.288
                            c0.154-0.028,0.301-0.053,0.441-0.082l-0.491-0.25l-0.035,0.009l-0.136,0.042l-0.042,0.015c-0.112,0.041-0.17,0.146-0.287,0.19
                            c-0.047,0.015-0.085,0.006-0.125,0c-0.056-0.018-0.115-0.032-0.167,0.015c-0.105,0.081-0.101,0.228-0.081,0.419
                            c-0.171-0.027-0.285-0.181-0.475-0.172c-0.231,0.023-0.428,0.005-0.428,0.005c-0.056-0.014-0.226-0.073-0.579-0.238
                            c-0.021,0.158-0.047,0.369-0.058,0.525C24.169,7.374,24.12,7.365,23.909,7.195z M22.23,7.879c-0.836,0.638-1.103,0.827-1.668,0.809
                            c0.1,0.019,0.185,0.05,0.437,0.186c0.739-0.224,0.818-0.254,1.009-0.469c0.11,0.014,0.187,0.049,0.33,0.159
                            c0.114-0.143,0.266-0.339,0.379-0.488c0.417-0.028,0.614-0.22,0.81-0.813c0.056-0.149-0.134-0.296-0.179-0.292
                            c-0.045,0.009-0.083,0.038-0.152,0.101c-0.157-0.076-0.468-0.004-0.764,0.064c-0.283,0.062-0.43,0.085-0.535,0.085
                            C21.929,7.409,21.983,7.536,22.23,7.879z M20.088,9.37c-0.449,0.146-0.449,0.146-0.713,0.355c0.021,0.009,0.021,0.009,0.234,0.425
                            c0.383-0.183,1.112,0.067,1.175,0.478c-0.423,0.498-0.64,0.79-0.932,1.264c-0.301-0.014-0.584,0.091-0.876,0.133
                            c0.31,0.023,0.644,0.049,0.976,0.067l0.388,0.712c0.727-1.391,1.188-1.109,1.677-0.626c0.126-0.129,0.358-0.354,0.414-0.703
                            l0.009-0.105c-0.35,0.119-0.321-0.412-0.301-0.694c-0.309-0.205-0.404-0.35-0.39-0.588c-0.098-0.009-0.243-0.058-0.503-0.168
                            c-0.301-0.133-0.411-0.133-0.511-0.096c-0.054-0.125-0.134-0.274-0.189-0.397c-0.143,0.096-0.252,0.163-0.5,0.35
                            c-0.056-0.024-0.042-0.129,0.072-0.425L20.088,9.37z M18.649,7.5c0.404-0.058,0.404-0.058,0.593-0.138
                            c-0.009,0.082-0.009,0.133,0.009,0.196c0.026-0.019,0.075-0.047,0.287-0.206c0.087-0.073,0.087-0.073,0.145-0.42l-0.049,0.033
                            C18.825,7.444,18.825,7.444,18.649,7.5z M19.324,8.423l0.021-0.014C19.351,8.3,19.36,8.149,19.36,8.04l-0.029,0.014
                            c-0.224,0.106-0.366,0.182-0.366,0.182L18.95,8.265c-0.072,0.145-0.156,0.287-0.252,0.439C18.771,8.708,18.963,8.729,19.324,8.423z
                             M19.229,8.938l-0.016,0.032c-0.054,0.111-0.162,0.316-0.08,0.914l0.009,0.047C19.255,9.649,19.255,9.343,19.229,8.938z
                             M16.794,8.189l0.026,0.096c0.691,0.009,0.691,0.009,0.719,0.015c-0.143,0.081-0.326,0.186-0.469,0.259
                            c0.216,0.059,0.299,0.311,0.52,0.364c0.129,0.019,0.248,0.042,0.372,0.059c0.121-0.146,0.238-0.288,0.359-0.436l0.054-0.063
                            c-0.144,0.096-0.306,0.145-0.455,0.229c0.002-0.143-0.056-0.265-0.033-0.406c0.021-0.12,0.049-0.191,0.145-0.302h-0.041
                            c-0.141-0.027-0.283,0-0.419-0.027c-0.084-0.02-0.098-0.058-0.105-0.078C17.078,8.044,16.923,8.11,16.794,8.189z M15.159,9.696
                            c0.28,0.521,1.187,0.602,2.291-0.268l0.134-0.106c-0.458-0.077-0.511-0.219-0.549-0.315c-0.086-0.004-0.191,0.023-0.479,0.138
                            c-0.105-0.096-0.25-0.22-0.357-0.316c-0.004-0.283-0.004-0.283-0.194-0.446l0.007,0.015c-0.026,0.214-0.259,0.319-0.271,0.536
                            c-0.009,0.138,0.062,0.269,0.02,0.407c-0.026,0.081-0.062,0.154-0.101,0.234C15.633,9.57,15.342,9.614,15.159,9.696z
                             M17.635,46.853c-1.509-3.189-2.38-6.741-2.38-10.493c0-1.011,0.08-1.996,0.196-2.969c-1.179-0.149-1.205-0.177-1.569-1.327
                            l-0.297-0.292c-0.817-0.766-1.841-1.154-2.922-1.565l-0.717,0.072c-0.631-0.364-0.896-0.526-1.462-0.867
                            c-0.84,0.791-1.107,0.613-2.856-0.525c-0.01-0.142-0.503-0.957-1.446-1.508L4.015,26.98c0.044-0.373,0.091-0.747,0.153-1.125
                            c0.35-0.393,0.41-0.502,0.548-0.981c-0.439-0.042-0.713,0.129-1.224,0.713c-0.218-0.087-0.426-0.183-0.624-0.278
                            c-0.075-0.292-0.306-1.326-0.149-2.403c0.375-0.173,0.375-0.173,1.137-1.551c1.378,0.047,1.378,0.047,2.202,0.593
                            c0.207-0.109,0.475-0.259,0.68-0.365l0.209,0.652c0.266,0.027,0.319,0.027,0.733-0.125c-0.171,0.905,0.076,1.801,0.034,2.696
                            c0.32-0.265,0.755-0.612,1.074-0.876l-0.341-0.722c0.129-0.425,0.208-0.662,0.596-1.337c-0.049,0.183-0.096,0.36-0.145,0.543
                            c0.459-0.316,1.016-0.452,1.48-0.753c0.39-0.004,0.699,0.054,1.016,0.114c1.002-1.182,1.002-1.182,1.306-1.469
                            c1.674-0.716,1.674-0.716,6.02-1.861c-0.403-0.087-0.403-0.087-0.731-0.451c0.156-0.169,0.364-0.383,0.527-0.557
                            c-0.037-0.004-0.037-0.004-1.946,0.334c0.958-0.747,1.85-0.803,3.521-0.747c0.401,0.019,0.401,0.019,1.979-1.272
                            c-0.439,0.022-0.564,0.053-1.16,0.296c0.072-0.341,0.072-0.341,0.241-0.689c-0.106-0.183-0.241-0.435-0.341-0.622
                            c0.105-0.32,0.105-0.32,0.297-0.662c-0.493-0.245-0.918-0.219-1.442-0.109c-0.71-1.164-0.71-1.164-1.088-1.226
                            c-0.007,0.201-0.017,0.464-0.029,0.66c-0.243-0.018-0.486-0.041-0.715-0.062c-0.908,1.014-1.995,1.852-2.915,2.857
                            c-0.478-0.542-0.756-2.186-0.79-2.748c0.283-0.143,0.662-0.33,0.947-0.464l0.727-0.365c2.307-1.411,2.307-1.411,3.155-1.025
                            c0.191-0.172,0.447-0.401,0.643-0.569l-0.157-0.43c-0.464,0.214-0.621,0.25-0.89,0.315c-0.252-0.493-0.081-1.023-0.145-1.532
                            l-0.069-0.033c-0.907,1.627-0.907,1.627-1.89,1.579c-0.021-0.063-0.049-0.143-0.075-0.21c-0.424-0.033-0.469-0.049-0.908-0.219
                            c-0.203,0.09-0.465,0.206-0.666,0.292c0.028-0.053,0.062-0.133,0.091-0.191c-0.125-0.038-0.294-0.068-0.423-0.091
                            c-0.016-0.143-0.044-0.334-0.063-0.483c-1.952,0.972-1.969,0.948-2.258,0.633c0.91-0.182,1.003-0.201,2.036-0.684
                            c-0.156-0.042-0.306-0.087-0.453-0.138l0.173-0.417c-0.196-0.072-0.383-0.137-0.559-0.196c-0.504,0.196-0.663,0.201-1.1,0.049
                            c0.137-0.19,0.311-0.446,0.446-0.637c-0.274-0.063-0.421-0.11-1.236,0.077c0.021-0.049,0.062-0.1,0.091-0.154
                            c-0.97,0.622-1.88,1.311-2.73,2.071c0.004,0.004,0.009,0.004,0.014,0.004c-0.246,0.22-0.462,0.407-0.687,0.608
                            c-3.787,3.676-6.188,8.75-6.393,14.379c3.203,2.24,4.714,5.959,4.732,5.994c-2.441,3.752-0.397,4.852,0.956,5.577
                            c0.087,0.333,0.185,0.67,0.285,0.994c0.555,0.557,2.3,2.085,3.786,2.51c0.467,2.292,1.754,4.097,3.749,5.412
                            C16.03,46.396,16.83,46.64,17.635,46.853z M10.698,26.871l-0.715-0.388l-0.119,0.033c-0.435,0.123-0.499,0.158-0.312,0.857
                            c0.468-0.05,0.468-0.05,1.028,0.637l0.547-0.498L10.698,26.871z M8.388,25.839c-0.406-0.521-0.413-0.53-1.07-0.187
                            C7.3,25.479,7.227,24.93,6.704,24.93c-0.316-0.005-0.505-0.023-0.804-0.063C5.991,25,6.244,25.351,7.182,25.619l0.136,0.033
                            c0.358,0.613,0.358,0.613,1.241,0.809l0.239-0.207C8.706,26.222,8.604,26.117,8.388,25.839z M33.769,36.59
                            c-2.077,1.455-3.49,2.714-4.239,3.779c-0.753,1.059-1.131,2.336-1.131,3.83l0,0h11.098v-2.814h-7.12l0.007-0.023
                            c0.54-0.748,1.54-1.594,3.006-2.522c1.585-1.015,2.664-1.921,3.248-2.729c0.57-0.814,0.862-1.838,0.862-3.07
                            c0-1.509-0.507-2.737-1.515-3.686c-1.022-0.954-2.331-1.431-3.938-1.431c-1.713,0-3.064,0.52-4.042,1.551
                            c-0.976,1.039-1.44,2.437-1.392,4.202h3.139c-0.028-0.948,0.162-1.698,0.57-2.25c0.409-0.546,0.979-0.822,1.705-0.822
                            c0.685,0,1.237,0.219,1.653,0.661c0.422,0.439,0.625,1.014,0.625,1.738c0,0.661-0.189,1.265-0.566,1.801
                            C35.35,35.34,34.701,35.938,33.769,36.59z M46.89,44.194v-3.579h-6.442v-1.389v-1.383l6.018-9.928h1.784h1.788v10.151h1.832v2.548
                            h-1.832v3.579H46.89z M46.89,38.067v-6.779l-3.946,6.555l-0.135,0.224H46.89L46.89,38.067z M40.893,17.258h-1.521v3.052h1.521
                            V17.258z M40.893,51.812h-1.521v3.047h1.521V51.812z M58.934,36.82v-1.524h-3.055v1.524H58.934z M24.384,36.82v-1.524h-3.049v1.524
                            H24.384z" fill={props.fill} />
                    </g>
                </svg>
            break;
        case 'sign-out':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 491.213 491.213" style={{...style}}>
                    <g>
                        <path d="M318.713,73.106c-32.723,0-64.571,9.208-92.103,26.628c-26.772,16.94-48.365,40.868-62.445,69.196l26.865,13.353
		                    c24.272-48.838,73.198-79.176,127.683-79.176c78.575,0,142.5,63.925,142.5,142.5s-63.925,142.5-142.5,142.5
                            c-54.484,0-103.41-30.338-127.683-79.176l-26.865,13.353c14.08,28.328,35.673,52.256,62.445,69.196
                            c27.531,17.42,59.38,26.628,92.103,26.628c95.117,0,172.5-77.383,172.5-172.5S413.83,73.106,318.713,73.106z" fill={props.fill} />
                        <polygon points="318.713,260.606 318.713,230.606 57.426,230.606 91.819,196.213 70.606,175 0,245.607 70.606,316.213 91.819,295
		                    57.425,260.606" fill={props.fill} />
                    </g>
                </svg>

            break;
        case 'home':
            icon =
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201.865 201.865" style={{...style}}>
                    <g>
                        <path d="M200.65,105.892l-21.763-19.709V39.168c0-2.026-1.643-3.665-3.665-3.665h-19.158
                            c-0.973,0-1.908,0.383-2.598,1.074c-0.691,0.691-1.077,1.625-1.066,2.602l0.05,23.059l-47.466-42.993
                            c-1.389-1.256-3.482-1.267-4.889-0.032L1.247,106.278c-1.263,1.109-1.61,2.924-0.841,4.42c0.759,1.485,2.434,2.28,4.066,1.908
                            l21.971-4.96v67.758c-0.021,0.591-0.032,3.647,2.18,5.944c0.981,1.009,2.738,2.222,5.569,2.222c5.282,0,49.027-0.054,49.027-0.054
                            c2.029,0,3.661-1.643,3.665-3.665l0.057-40.509c-0.036-0.472,0.05-1.671,0.537-2.205c0.329-0.351,1.034-0.433,1.557-0.433h20.353
                            c0.913,0,2.147,0.147,2.781,0.805c0.698,0.716,0.687,1.961,0.676,2.154l-0.093,40.058c0,0.97,0.379,1.904,1.07,2.598
                            c0.687,0.687,1.632,1.081,2.598,1.081h48.003c3.264,0,5.268-1.378,6.363-2.527c2.559-2.663,2.473-6.313,2.459-6.564V106.54
                            l24.111,5.64c1.643,0.39,3.307-0.39,4.091-1.868C202.225,108.834,201.896,107.019,200.65,105.892z M159.744,42.836h11.817v36.705
                            l-11.76-10.651L159.744,42.836z M170.409,98.344c-1.081-0.258-2.24,0-3.11,0.698c-0.873,0.694-1.389,1.754-1.389,2.874v72.486
                            c0,0.394-0.143,1.12-0.419,1.403c-0.225,0.222-0.762,0.251-1.07,0.251h-44.328l0.079-36.129c0.032-0.44,0.218-4.366-2.609-7.401
                            c-1.356-1.435-3.858-3.153-8.181-3.153H89.029c-3.654,0-5.83,1.557-7.011,2.859c-2.516,2.788-2.473,6.524-2.409,7.573
                            l-0.057,36.383c-10.629,0.011-41.017,0.05-45.366,0.05c-0.132,0-0.215-0.007-0.268-0.007c-0.007,0-0.018,0-0.025,0
                            c-0.068-0.147-0.118-0.426-0.118-0.676v-72.493c0-1.113-0.515-2.169-1.381-2.867c-0.873-0.694-2.015-0.948-3.096-0.712
                            l-12.433,2.806l85.613-75.406l49.986,45.269v0.218h0.236l32.51,29.447L170.409,98.344z" fill={props.fill} />
                    </g>
                </svg>
            break;
        case 'terms-and-conditions':
            icon =
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511 511" style={{...style}}>
                    <g>
                        <path d="M454.962,110.751c-0.018-0.185-0.05-0.365-0.081-0.545c-0.011-0.06-0.016-0.122-0.028-0.182
                            c-0.043-0.215-0.098-0.425-0.159-0.632c-0.007-0.025-0.012-0.052-0.02-0.077c-0.065-0.213-0.141-0.421-0.224-0.625
                            c-0.008-0.021-0.015-0.043-0.023-0.064c-0.081-0.195-0.173-0.384-0.269-0.57c-0.016-0.031-0.029-0.063-0.045-0.094
                            c-0.093-0.173-0.196-0.339-0.301-0.504c-0.027-0.042-0.049-0.086-0.077-0.127c-0.103-0.154-0.216-0.3-0.33-0.446
                            c-0.037-0.048-0.07-0.098-0.109-0.145c-0.142-0.173-0.294-0.338-0.451-0.498c-0.015-0.015-0.027-0.031-0.042-0.046l-104-104
                            c-0.018-0.018-0.038-0.033-0.057-0.051c-0.156-0.153-0.317-0.301-0.486-0.44c-0.055-0.045-0.113-0.083-0.169-0.126
                            c-0.138-0.107-0.275-0.214-0.42-0.311c-0.051-0.034-0.105-0.062-0.156-0.095c-0.156-0.099-0.312-0.197-0.475-0.284
                            c-0.036-0.019-0.074-0.035-0.111-0.053c-0.181-0.093-0.365-0.183-0.554-0.262c-0.024-0.01-0.049-0.017-0.074-0.027
                            c-0.202-0.081-0.406-0.157-0.616-0.221c-0.027-0.008-0.054-0.013-0.081-0.021c-0.206-0.06-0.415-0.115-0.628-0.158
                            c-0.063-0.013-0.128-0.018-0.192-0.029c-0.177-0.031-0.354-0.062-0.536-0.08C344.001,0.013,343.751,0,343.5,0h-248
                            C73.72,0,56,17.72,56,39.5v432c0,21.78,17.72,39.5,39.5,39.5h320c21.78,0,39.5-17.72,39.5-39.5v-360
                            C455,111.249,454.987,110.999,454.962,110.751z M351,25.606L429.394,104H375.5c-13.509,0-24.5-10.99-24.5-24.5V25.606z M415.5,496
                            h-320C81.991,496,71,485.01,71,471.5v-432C71,25.99,81.991,15,95.5,15H336v64.5c0,21.78,17.72,39.5,39.5,39.5H440v352.5
                            C440,485.01,429.009,496,415.5,496z" fill={props.fill} />
                        <path d="M391.5,248h-48.002c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5H391.5c4.142,0,7.5-3.357,7.5-7.5S395.642,248,391.5,248z
                            " fill={props.fill} />
                        <path d="M119.5,263h192.001c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5H119.5c-4.142,0-7.5,3.357-7.5,7.5S115.358,263,119.5,263z"
                              fill={props.fill} />
                        <path d="M391.5,152h-200c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h200c4.142,0,7.5-3.357,7.5-7.5S395.642,152,391.5,152z" fill={props.fill} />
                        <path d="M119.5,167h40.003c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5H119.5c-4.142,0-7.5,3.357-7.5,7.5S115.358,167,119.5,167z
                            " fill={props.fill} />
                        <path d="M391.5,344h-152c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h152c4.142,0,7.5-3.357,7.5-7.5S395.642,344,391.5,344z" fill={props.fill} />
                        <path d="M207.5,344h-88c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h88c4.142,0,7.5-3.357,7.5-7.5S211.642,344,207.5,344z" fill={props.fill} />
                        <path d="M391.5,200h-272c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h272c4.142,0,7.5-3.357,7.5-7.5S395.642,200,391.5,200z" fill={props.fill} />
                        <path d="M391.5,296h-272c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h272c4.142,0,7.5-3.357,7.5-7.5S395.642,296,391.5,296z" fill={props.fill} />
                    </g>
                </svg>
            break;
        case 'privacy-policy':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{...style}}>
                    <g>
                        <g>
                            <path d="M256,355.404c-13.516,0-24.511,10.996-24.511,24.511c0,10.651,6.831,19.733,16.34,23.105v25.916
                                c0,4.513,3.658,8.17,8.17,8.17c4.512,0,8.17-3.657,8.17-8.17V403.02c9.509-3.373,16.34-12.455,16.34-23.105
                                C280.511,366.4,269.516,355.404,256,355.404z M256,388.085c-4.506,0-8.17-3.665-8.17-8.17c0-4.506,3.665-8.17,8.17-8.17
                                c4.506,0,8.17,3.665,8.17,8.17C264.17,384.42,260.506,388.085,256,388.085z" fill={props.fill} />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M373.106,212.426c40.546,0,73.532-32.986,73.532-73.532c0-40.546-32.986-73.532-73.532-73.532
                                c-6.157,0-12.229,0.759-18.137,2.266c-7.138-18.216-19.388-34.436-35.089-46.275C301.352,7.384,279.263,0,256,0
                                c-23.263,0-45.352,7.384-63.88,21.354c-15.701,11.838-27.953,28.059-35.089,46.275c-5.908-1.508-11.98-2.267-18.137-2.267
                                c-40.546,0-73.532,32.986-73.532,73.532c0,40.546,32.986,73.532,73.532,73.532c4.512,0,8.17-3.657,8.17-8.17
                                c0-4.513-3.658-8.17-8.17-8.17c-31.536,0-57.191-25.657-57.191-57.192s25.656-57.191,57.191-57.191
                                c6.987,0,13.832,1.257,20.342,3.738c2.117,0.805,4.475,0.697,6.511-0.302c2.035-1,3.562-2.799,4.218-4.97
                                C181.324,42.588,216.703,16.34,256,16.34c39.297,0,74.676,26.247,86.036,63.828c0.656,2.17,2.183,3.97,4.218,4.97
                                c2.035,1,4.395,1.109,6.511,0.302c6.51-2.48,13.354-3.738,20.342-3.738c31.536,0,57.191,25.657,57.191,57.191
                                s-25.656,57.192-57.191,57.192h-24.892c-4.153-47.242-43.912-84.426-92.214-84.426c-51.057,0-92.596,41.538-92.596,92.596v76.255
                                h-24.511c-4.512,0-8.17,3.657-8.17,8.17V503.83c0,4.513,3.658,8.17,8.17,8.17h234.213c4.512,0,8.17-3.657,8.17-8.17V288.681
                                c0-4.513-3.658-8.17-8.17-8.17h-24.511v-68.085H373.106z M179.745,204.255C179.745,162.208,213.953,128,256,128
                                s76.255,34.208,76.255,76.255v76.255h-16.34v-76.255c0-33.037-26.878-59.915-59.915-59.915s-59.915,26.878-59.915,59.915v76.255
                                h-16.34V204.255z M275.064,196.085h-61.859c3.837-20.134,21.561-35.404,42.796-35.404c24.027,0,43.574,19.548,43.574,43.574
                                v76.255h-87.149v-68.085h62.638c4.512,0,8.17-3.657,8.17-8.17C283.234,199.742,279.576,196.085,275.064,196.085z M364.936,296.851
                                V495.66H147.064V296.851H364.936z" fill={props.fill} />
                        </g>
                    </g>
                    <g>
                        <g>
                            <circle cx="212.426" cy="462.979" r="8.17" fill={props.fill} />
                        </g>
                    </g>
                    <g>
                        <g>
                            <circle cx="179.745" cy="430.298" r="8.17" fill={props.fill} />
                        </g>
                    </g>
                </svg>
            break;
        case 'faq':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 502 502" style={{...style}}>
                    <g>
                        <g>
                            <g>
                                <path d="M428.483,73.516C381.076,26.108,318.045,0,251,0C183.956,0,120.924,26.108,73.516,73.516S0,183.956,0,251
                                    s26.108,130.076,73.516,177.484C120.924,475.892,183.956,502,251,502c67.045,0,130.076-26.108,177.483-73.516
                                    C475.892,381.076,502,318.044,502,251S475.892,120.924,428.483,73.516z M251,482C123.626,482,20,378.374,20,251
                                    S123.626,20,251,20s231,103.626,231,231S378.374,482,251,482z" fill={props.fill} />
                                <path d="M251,41.36c-71.131,0-129,57.869-129,129c0,19.299,15.701,35,35,35s35-15.701,35-35c0-32.533,26.467-59,59-59
                                    c32.532,0,59,26.467,59,59c0,32.809-26.468,59.5-59,59.5c-19.299,0-35,15.701-35,35v68.781c0,19.299,15.701,35,35,35
                                    s35-15.702,35-34.999v-38.638c25.275-7.181,48.08-22.162,64.871-42.763C369.655,229.195,380,200.116,380,170.36
                                    C380,99.229,322.131,41.36,251,41.36z M273.893,277.422c-4.605,0.992-7.893,5.064-7.893,9.775v46.443c0,8.271-6.729,15-15,15
                                    s-15-6.729-15-15v-68.781c0-8.271,6.729-15,15-15c43.561,0,79-35.664,79-79.5c0-43.561-35.439-79-79-79c-43.561,0-79,35.44-79,79
                                    c0,8.271-6.729,15-15,15s-15-6.729-15-15c0-60.103,48.897-109,109-109c60.103,0,109,48.897,109,109
                                    C360,221.641,323.787,266.667,273.893,277.422z" fill={props.fill} />
                                <path d="M251,386.641c-19.299,0-35,15.701-35,35v4c0,19.299,15.701,35,35,35s35-15.702,35-35v-4
                                    C286,402.342,270.299,386.641,251,386.641z M266,425.641c0,8.271-6.729,15-15,15s-15-6.729-15-15v-4c0-8.271,6.729-15,15-15
                                    s15,6.729,15,15V425.641z" fill={props.fill} />
                                <path d="M59.077,290.972C56.372,277.911,55,264.462,55,251c0-5.523-4.477-10-10-10s-10,4.477-10,10
                                    c0,14.822,1.512,29.636,4.492,44.028c0.979,4.724,5.14,7.974,9.782,7.974c0.672,0,1.355-0.068,2.039-0.21
                                    C56.721,301.672,60.197,296.38,59.077,290.972z" fill={props.fill} />
                                <path d="M172.982,430.859c-43.999-19.113-79.407-53.593-99.701-97.088c-2.335-5.005-8.287-7.169-13.29-4.834
                                    c-5.005,2.335-7.169,8.285-4.834,13.29c22.36,47.925,61.375,85.916,109.856,106.976c1.297,0.564,2.649,0.831,3.979,0.831
                                    c3.862,0,7.54-2.25,9.178-6.019C180.37,438.949,178.047,433.059,172.982,430.859z" fill={props.fill} />
                            </g>
                        </g>
                    </g>
                </svg>
            break;
        case 'facebook':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310" style={{...style}}>
                    <g id="XMLID_834_">
                        <path id="XMLID_835_" d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064
		                    c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996
                            V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545
                            C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703
                            c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z" fill={props.fill} />
                    </g>
                </svg>
            break;
        case 'twitter':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310" style={{...style}}>
                    <g id="XMLID_826_">
                        <path id="XMLID_827_" d="M302.973,57.388c-4.87,2.16-9.877,3.983-14.993,5.463c6.057-6.85,10.675-14.91,13.494-23.73
                            c0.632-1.977-0.023-4.141-1.648-5.434c-1.623-1.294-3.878-1.449-5.665-0.39c-10.865,6.444-22.587,11.075-34.878,13.783
                            c-12.381-12.098-29.197-18.983-46.581-18.983c-36.695,0-66.549,29.853-66.549,66.547c0,2.89,0.183,5.764,0.545,8.598
                            C101.163,99.244,58.83,76.863,29.76,41.204c-1.036-1.271-2.632-1.956-4.266-1.825c-1.635,0.128-3.104,1.05-3.93,2.467
                            c-5.896,10.117-9.013,21.688-9.013,33.461c0,16.035,5.725,31.249,15.838,43.137c-3.075-1.065-6.059-2.396-8.907-3.977
                            c-1.529-0.851-3.395-0.838-4.914,0.033c-1.52,0.871-2.473,2.473-2.513,4.224c-0.007,0.295-0.007,0.59-0.007,0.889
                            c0,23.935,12.882,45.484,32.577,57.229c-1.692-0.169-3.383-0.414-5.063-0.735c-1.732-0.331-3.513,0.276-4.681,1.597
                            c-1.17,1.32-1.557,3.16-1.018,4.84c7.29,22.76,26.059,39.501,48.749,44.605c-18.819,11.787-40.34,17.961-62.932,17.961
                            c-4.714,0-9.455-0.277-14.095-0.826c-2.305-0.274-4.509,1.087-5.294,3.279c-0.785,2.193,0.047,4.638,2.008,5.895
                            c29.023,18.609,62.582,28.445,97.047,28.445c67.754,0,110.139-31.95,133.764-58.753c29.46-33.421,46.356-77.658,46.356-121.367
                            c0-1.826-0.028-3.67-0.084-5.508c11.623-8.757,21.63-19.355,29.773-31.536c1.237-1.85,1.103-4.295-0.33-5.998
                            C307.394,57.037,305.009,56.486,302.973,57.388z" fill={props.fill} />
                    </g>
                </svg>
            break;
        case 'linkedin':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310" style={{...style}}>
                    <g id="XMLID_801_">
                        <path id="XMLID_802_" d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
		                    C77.16,101.969,74.922,99.73,72.16,99.73z" fill={props.fill} />
                        <path id="XMLID_803_" d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
		                    c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z" fill={props.fill} />
                        <path id="XMLID_804_" d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
                            c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
                            c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
                            C310,145.43,300.549,94.761,230.454,94.761z" fill={props.fill} />
                    </g>
                </svg>
            break;
        case 'view':
            icon =
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.794 80.794" style={{...style}}>
                    <g>
                        <g>
                            <path d="M79.351,38.549c-0.706-0.903-17.529-22.119-38.953-22.119c-21.426,0-38.249,21.216-38.955,22.119L0,40.396l1.443,1.847
                                c0.706,0.903,17.529,22.12,38.955,22.12c21.424,0,38.247-21.217,38.953-22.12l1.443-1.847L79.351,38.549z M40.398,58.364
                                c-15.068,0-28.22-13.046-32.643-17.967c4.425-4.922,17.576-17.966,32.643-17.966c15.066,0,28.218,13.045,32.642,17.966
                                C68.614,45.319,55.463,58.364,40.398,58.364z" fill={props.fill} />
                            <path d="M40.397,23.983c-9.052,0-16.416,7.363-16.416,16.414c0,9.053,7.364,16.417,16.416,16.417s16.416-7.364,16.416-16.417
                                C56.813,31.346,49.449,23.983,40.397,23.983z M40.397,50.813c-5.744,0-10.416-4.673-10.416-10.417
                                c0-5.742,4.672-10.414,10.416-10.414c5.743,0,10.416,4.672,10.416,10.414C50.813,46.14,46.14,50.813,40.397,50.813z" fill={props.fill} />
                        </g>
                    </g>
                </svg>
            break;
        case 'photo':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{...style}}>
                    <g>
                        <g>
                            <g>
                                <path d="M507.001,190.421L394.859,78.28c-3.135-3.135-7.514-4.999-12.067-4.999H17.067C7.641,73.281,0,80.922,0,90.348v331.305
                                    c0,9.425,7.641,17.067,17.067,17.067h2.157h473.551h2.158c9.425,0,17.067-7.641,17.067-17.067V202.488
                                    C512,198.162,510.291,193.71,507.001,190.421z M399.859,131.55l53.871,53.872h-53.871V131.55z M55.124,404.586l127.441-156.553
                                    l51.428,63.175l-18.347,22.538c-14.236,17.485,12.213,39.061,26.472,21.55c2.996-3.68,83.835-102.985,87.316-107.263
                                    l127.441,156.553H55.124z M477.867,376.304L342.67,210.226c-6.829-8.388-19.648-8.38-26.472,0L256,284.176l-60.199-73.95
                                    c-6.829-8.388-19.648-8.38-26.472,0L34.133,376.304v-268.89h331.593v95.074c0,9.425,7.641,17.067,17.067,17.067h95.074V376.304z"
                                    fill={props.fill} />
                                <path d="M95.776,118.792c-27.716,0-50.265,22.549-50.265,50.265c0,27.715,22.548,50.264,50.265,50.264
                                    c27.715,0,50.265-22.548,50.265-50.265C146.041,141.34,123.492,118.792,95.776,118.792z M95.776,185.187
                                    c-8.894,0-16.131-7.236-16.131-16.131s7.236-16.13,16.131-16.13c8.894,0,16.131,7.236,16.131,16.13
                                    C111.907,177.95,104.671,185.187,95.776,185.187z" fill={props.fill} />
                            </g>
                        </g>
                    </g>
                </svg>
            break;
        case 'share':
            icon =
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 227.216 227.216" style={{...style}}>
                    <path d="M175.897,141.476c-13.249,0-25.11,6.044-32.98,15.518l-51.194-29.066c1.592-4.48,2.467-9.297,2.467-14.317
                        c0-5.019-0.875-9.836-2.467-14.316l51.19-29.073c7.869,9.477,19.732,15.523,32.982,15.523c23.634,0,42.862-19.235,42.862-42.879
                        C218.759,19.229,199.531,0,175.897,0C152.26,0,133.03,19.229,133.03,42.865c0,5.02,0.874,9.838,2.467,14.319L84.304,86.258
                        c-7.869-9.472-19.729-15.514-32.975-15.514c-23.64,0-42.873,19.229-42.873,42.866c0,23.636,19.233,42.865,42.873,42.865
                        c13.246,0,25.105-6.042,32.974-15.513l51.194,29.067c-1.593,4.481-2.468,9.3-2.468,14.321c0,23.636,19.23,42.865,42.867,42.865
                        c23.634,0,42.862-19.23,42.862-42.865C218.759,160.71,199.531,141.476,175.897,141.476z M175.897,15
                        c15.363,0,27.862,12.5,27.862,27.865c0,15.373-12.499,27.879-27.862,27.879c-15.366,0-27.867-12.506-27.867-27.879
                        C148.03,27.5,160.531,15,175.897,15z M51.33,141.476c-15.369,0-27.873-12.501-27.873-27.865c0-15.366,12.504-27.866,27.873-27.866
                        c15.363,0,27.861,12.5,27.861,27.866C79.191,128.975,66.692,141.476,51.33,141.476z M175.897,212.216
                        c-15.366,0-27.867-12.501-27.867-27.865c0-15.37,12.501-27.875,27.867-27.875c15.363,0,27.862,12.505,27.862,27.875
                        C203.759,199.715,191.26,212.216,175.897,212.216z" fill={props.fill} />
                </svg>
            break;
        case 'travel':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 475.766 475.766" style={{...style}}>
                    <g>
                        <path d="M237.883,99.02c-1.855,0.581-9.339,6.848-16.375,29.443c-5.719,18.365-9.284,41.583-10.309,66.537h53.368
		                    c-1.025-24.955-4.59-48.172-10.309-66.537C247.222,105.868,239.739,99.601,237.883,99.02z" fill={props.fill} />
                        <path d="M268.215,87.684c16.231,23.293,24.749,65.194,26.376,107.316h68.4C356.776,142.619,318.433,100.097,268.215,87.684z" fill={props.fill} />
                        <path d="M221.508,291.537c7.036,22.595,14.52,28.862,16.375,29.443c1.855-0.581,9.339-6.848,16.375-29.443
		                    c5.719-18.365,9.284-41.583,10.309-66.537h-53.368C212.224,249.955,215.789,273.172,221.508,291.537z" fill={props.fill} />
                        <path d="M112.776,195h68.4c1.627-42.121,10.145-84.023,26.375-107.316C157.333,100.097,118.99,142.619,112.776,195z" fill={props.fill} />
                        <path d="M112.776,225c6.215,52.381,44.557,94.903,94.775,107.316c-16.23-23.293-24.749-65.194-26.375-107.316H112.776z" fill={props.fill} />
                        <path d="M268.215,332.316c50.218-12.412,88.561-54.935,94.775-107.316h-68.4C292.964,267.121,284.446,309.023,268.215,332.316z" fill={props.fill} />
                        <polygon points="372.286,334.882 346.24,349.766 369.23,390 297.883,390 297.883,370 267.883,370 267.883,420 369.6,420
		                    346.24,460.882 372.286,475.766 404.153,420 447.883,420 447.883,390 403.783,390" fill={props.fill} />
                        <path d="M57.883,210c0-99.252,80.748-180,180-180s180,80.748,180,180h30c0-115.794-94.206-210-210-210s-210,94.206-210,210
		                    s94.206,210,210,210v-30C138.631,390,57.883,309.252,57.883,210z" fill={props.fill} />
                    </g>
                </svg>
            break;
        case 'architecture':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{...style}}>
                    <g>
                        <g>
                            <path d="M240.794,113.393H139.421v133.949h101.373v59.81h131.784v-59.81V113.393H240.794z M190.108,216.93h-20.275v-73.125h20.275
			                    V216.93z M240.794,216.93H220.52v-73.125h20.275V216.93z M342.167,276.74h-70.961v-29.398h70.961V276.74z M342.167,216.93h-70.961
			                    v-73.125h70.961V216.93z" fill={props.fill} />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M490.171,71.458V41.046h-22.302H271.206V0h-30.412v41.046H44.131H21.829v30.412h22.302v277.63H21.829V379.5h22.302
			                    h112.028l-26.557,126.239l29.76,6.261l15.584-74.08h65.847v73.992h30.412V437.92h65.847L352.636,512l29.76-6.261L355.839,379.5
			                    h112.028h22.302v-30.412h-22.302V71.458H490.171z M240.794,407.508h-59.449l5.892-28.008h53.557V407.508z M271.207,407.508V379.5
			                    h53.557l5.892,28.008H271.207z M437.457,349.088H74.543V71.458h362.914V349.088z" fill={props.fill} />
                        </g>
                    </g>
                </svg>
            break;
        case 'git':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291.32 291.32" style={{...style}}>
                    <g>
                        <path d="M145.66,0C65.219,0,0,65.219,0,145.66c0,80.45,65.219,145.66,145.66,145.66
                            s145.66-65.21,145.66-145.66C291.319,65.219,226.1,0,145.66,0z M186.462,256.625c-0.838-11.398-1.775-25.518-1.83-31.235
                            c-0.364-4.388-0.838-15.549-11.434-22.677c42.068-3.523,62.087-26.774,63.526-57.499c1.202-17.497-5.754-32.883-18.107-45.3
                            c0.628-13.282-0.401-29.023-1.256-35.941c-9.486-2.731-31.608,8.949-37.79,13.947c-13.037-5.062-44.945-6.837-64.336,0
                            c-13.747-9.668-29.396-15.64-37.926-13.974c-7.875,17.452-2.813,33.948-1.275,35.914c-10.142,9.268-24.289,20.675-20.447,44.572
                            c6.163,35.04,30.816,53.94,70.508,58.564c-8.466,1.73-9.896,8.048-10.606,10.788c-26.656,10.997-34.275-6.791-37.644-11.425
                            c-11.188-13.847-21.23-9.832-21.849-9.614c-0.601,0.218-1.056,1.092-0.992,1.511c0.564,2.986,6.655,6.018,6.955,6.263
                            c8.257,6.154,11.316,17.27,13.2,20.438c11.844,19.473,39.374,11.398,39.638,11.562c0.018,1.702-0.191,16.032-0.355,27.184
                            C64.245,245.992,27.311,200.2,27.311,145.66c0-65.365,52.984-118.348,118.348-118.348S264.008,80.295,264.008,145.66
                            C264.008,196.668,231.69,239.992,186.462,256.625z" fill={props.fill} />
                    </g>
                </svg>
            break;
        case 'sonar':
            icon =
                <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{...style}}>
                    <g id="Illustration_5">
                        <path d="M408.78,448.09H386.5c0-179.36-148-325.28-329.91-325.28V100.53C250.79,100.53,408.78,256.44,408.78,448.09Z" fill={props.fill} />
                        <path d="M424.18,328.48C397.43,216,306.27,122,192,89.2l5.12-17.84C317.73,106,414,205.23,442.24,324.19Z" fill={props.fill} />
                        <path d="M441.31,222.87c-27.55-60.08-74.49-112.46-132.17-147.51l7.72-12.7c60.19,36.58,109.18,91.27,138,154Z" fill={props.fill} />
                    </g>
                </svg>
            break;
        case 'invent':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{...style}}>
                    <g>
                        <g>
                            <g>
                                <path d="M377.436,50.032c-32.853-32.607-76.43-50.356-122.755-50.028C160.668,0.709,83.928,77.777,83.614,171.801
                                    c-0.152,45.628,17.352,88.693,49.288,121.263c29.356,29.94,45.524,69.427,45.524,111.194v26.405
                                    c0,17.933,12.41,33.013,29.09,37.126v5.971c0,21.085,17.155,38.24,38.24,38.24h20.488c21.085,0,38.24-17.155,38.24-38.24v-5.971
                                    c16.681-4.112,29.09-19.193,29.09-37.126v-26.405c0-41.49,16.34-81.763,44.831-110.49
                                    c32.231-32.501,49.981-75.609,49.981-121.382C428.387,126.096,410.293,82.643,377.436,50.032z M266.244,490.452h-20.488
                                    c-9.203,0-16.691-7.488-16.691-16.691v-4.857h53.871v4.857h0C282.935,482.964,275.447,490.452,266.244,490.452z M312.026,430.664
                                    c0,9.203-7.488,16.691-16.691,16.691h-78.669c-9.203,0-16.691-7.488-16.691-16.691v-15.631h112.051V430.664z M363.105,278.594
                                    c-29.981,30.231-48.153,71.613-50.755,114.891h-24.028V310.97l15.061-19.478c3.64-4.707,2.774-11.473-1.933-15.114
                                    c-4.707-3.637-11.473-2.774-15.114,1.933L269.025,300.7c-1.459,1.888-2.251,4.205-2.251,6.591v86.193h-21.548v-86.193
                                    c0-2.385-0.792-4.703-2.251-6.591l-45.252-58.522c-3.64-4.708-10.407-5.572-15.114-1.933c-4.707,3.641-5.572,10.407-1.933,15.114
                                    l43.001,55.611v82.514h-24.02c-2.559-43.423-20.572-84.097-51.37-115.506c-27.943-28.499-43.258-66.181-43.125-106.105
                                    c0.274-82.272,67.419-149.705,149.68-150.32c40.502-0.269,78.667,15.241,107.413,43.773
                                    c28.75,28.534,44.583,66.555,44.583,107.06C406.839,212.438,391.308,250.157,363.105,278.594z" fill={props.fill} />
                                <path d="M329.389,240.244c-4.709-3.64-11.474-2.773-15.113,1.935l-0.274,0.354c-3.639,4.708-2.773,11.474,1.935,15.114
                                    c1.962,1.516,4.281,2.25,6.581,2.25c3.221,0,6.41-1.438,8.532-4.185l0.274-0.354C334.964,250.65,334.097,243.884,329.389,240.244
                                    z" fill={props.fill} />
                                <path d="M360.349,151.431l-48.979-41.875c-4.522-3.867-11.324-3.335-15.191,1.188c-3.867,4.522-3.336,11.324,1.188,15.19
                                    l39.401,33.687l-39.401,33.687c-4.523,3.867-5.055,10.668-1.188,15.19c2.131,2.492,5.153,3.773,8.194,3.773
                                    c2.476,0,4.966-0.849,6.996-2.585l48.979-41.875c2.395-2.046,3.773-5.039,3.773-8.189
                                    C364.121,156.473,362.743,153.477,360.349,151.431z" fill={props.fill} />
                                <path d="M217.977,110.744c-3.867-4.523-10.669-5.056-15.19-1.188l-48.979,41.875c-2.395,2.046-3.773,5.039-3.773,8.189
                                    c0,3.15,1.378,6.142,3.773,8.189l48.979,41.875c2.03,1.736,4.519,2.585,6.996,2.585c3.04,0,6.064-1.28,8.194-3.773
                                    c3.867-4.522,3.336-11.324-1.188-15.19l-39.401-33.687l39.401-33.687C221.311,122.067,221.844,115.267,217.977,110.744z" fill={props.fill} />
                                <path d="M271.721,90.91c-5.819-1.238-11.542,2.472-12.784,8.292l-24.795,116.347c-1.24,5.819,2.472,11.542,8.292,12.782
                                    c0.757,0.161,1.512,0.239,2.256,0.239c4.977,0,9.448-3.468,10.527-8.531l24.796-116.346
                                    C281.254,97.873,277.541,92.151,271.721,90.91z" fill={props.fill} />
                            </g>
                        </g>
                    </g>
                </svg>
            break;
        case 'tool':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.347 489.347" style={{...style}}>
                    <g>
                        <path d="M412.642,345.939l-59.523-14.229l-66.352-66.352l51.12-51.055c11.874,4.167,24.216,6.203,36.499,6.202
                            c28.736-0.002,57.122-11.149,78.233-32.221c32.686-32.626,41.544-82.646,22.043-124.466l-9.042-19.391l-53.807,53.682
                            l-24.986-24.941l53.822-53.696L421.17,10.42C379.3-9.036,329.218-0.195,296.546,32.417
                            c-30.131,30.078-40.012,74.943-26.092,114.534l-20.111,20.086L102.13,18.837C91.061,7.731,76.32,1.605,60.621,1.587
                            c-0.023,0-0.044,0-0.067,0c-15.696,0-30.45,6.104-41.553,17.195C7.886,29.897,1.77,44.669,1.778,60.378
                            c0.008,15.697,6.129,30.456,17.233,41.553L167.18,250.094l-20.155,20.129c-39.652-13.917-84.597-4.061-114.733,26.02
                            C-0.393,328.869-9.252,378.888,10.25,420.708l9.042,19.391l53.806-53.681l24.986,24.94l-53.822,53.697l19.48,9.051
                            c14.814,6.883,30.652,10.224,46.388,10.224c28.738-0.001,57.124-11.148,78.235-32.221c30.132-30.078,40.013-74.943,26.093-114.534
                            l51.082-51.018l66.366,66.366l14.229,59.523l76.705,76.706l66.507-66.507L412.642,345.939z M301.691,144.194
                            c-14.181-30.419-7.73-66.807,16.05-90.545c18.28-18.246,44.036-26.278,68.827-22.6l-42.211,42.113l67.451,67.328l42.24-42.142
                            c3.697,24.738-4.343,50.456-22.622,68.702c-23.802,23.759-60.288,30.197-90.793,16.02l-9.505-4.417l-34.603,34.559l-24.968-24.965
                            l34.573-34.529L301.691,144.194z M31.778,60.362c-0.004-7.69,2.992-14.923,8.43-20.362c5.433-5.426,12.657-8.414,20.347-8.414
                            c7.711,0.009,14.918,3.002,20.345,8.446l194.398,194.38l-40.711,40.659L40.221,80.714C34.781,75.277,31.782,68.049,31.778,60.362z
                             M167.171,430.877c-18.28,18.246-44.038,26.278-68.827,22.6l42.211-42.112l-67.451-67.329l-42.24,42.142
                            c-3.698-24.737,4.343-50.455,22.623-68.702c23.801-23.758,60.288-30.197,90.792-16.021l9.505,4.417l34.609-34.565l24.967,24.966
                            l-34.578,34.534l4.44,9.525C197.403,370.751,190.952,407.138,167.171,430.877z M373.342,397.227l-7.564-31.645l31.646,7.564
                            l49.498,49.499l-24.081,24.081L373.342,397.227z" fill={props.fill} />
                    </g>
                </svg>
            break;
        case 'folderopen':
            icon =
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 490" style={{...style}}>
                    <g>
                        <g>
                            <polygon points="56.8,193.3 0,426.2 433.2,426.2 490,193.3" fill={props.fill} />
                            <polygon points="433.2,169.6 433.2,130.3 174.2,130.3 131.5,63.8 0,63.8 0,326.3 38.2,169.6" fill={props.fill} />
                        </g>
                    </g>
                </svg>
            break;
        case 'folderclose':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440 440" style={{...style}}>
                    <g>
                        <path d="M420,78.072V60c0-16.5-13.5-30-30-30H240v40h150C400.925,70,411.167,72.949,420,78.072z" fill={props.fill} />
                        <path d="M340.57,211.141c29.914,0,57.487,10.205,79.43,27.311V130c0-16.5-13.5-30-30-30H210V30c0-16.5-13.5-30-30-30H30
		                    C13.5,0,0,13.5,0,30v310.57h211.141C211.141,269.202,269.202,211.141,340.57,211.141z" fill={props.fill} />
                        <path d="M340.57,241.141c-54.825,0-99.43,44.604-99.43,99.43S285.745,440,340.57,440c54.826,0,99.43-44.604,99.43-99.43
		                    S395.396,241.141,340.57,241.141z M402.071,355.57h-46.5v46.5h-30v-46.5h-46.5v-30h46.5v-46.5h30v46.5h46.5V355.57z" fill={props.fill} />
                    </g>
                </svg>
            break;

        case 'property-buy-sell':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{...style}}>
                    <g transform="translate(1 1)">
                        <g>
                            <g>
                                <path d="M141.37,257.95c-1.71-1.97-3.41-2.95-5.97-2.95s-4.27,0.98-5.97,2.95L35.56,366.26c-3.41,3.94-3.41,9.85,0,13.78
                                    l25.6,29.54c1.71,1.97,3.41,2.95,5.97,2.95s4.27-0.98,5.97-2.95l2.56-2.95v55.14c0,5.91,3.41,9.85,8.53,9.85h102.4
                                    c5.12,0,8.53-3.94,8.53-9.85v-55.14l2.56,2.95c1.71,1.97,3.41,2.95,5.97,2.95s4.27-0.98,5.97-2.95l25.6-29.54
                                    c1.71-1.97,2.56-3.94,2.56-6.89c0-2.95-0.85-4.92-2.56-6.89L141.37,257.95z M178.07,451.92H92.73v-64.98l42.67-49.23l42.67,49.23
                                    L178.07,451.92L178.07,451.92z M203.67,388.91l-11.09-12.8l-51.2-59.08c-0.85-1.97-2.56-2.95-4.27-2.95c0,0-0.85,0-1.71,0
                                    c-2.56,0-4.27,0.98-5.97,2.95l-51.2,59.08l-11.09,12.8l-13.65-15.75l81.92-94.52l81.92,94.52L203.67,388.91z" fill={props.fill} />
                                <path d="M263.4,304.23c0,5.91,3.41,9.85,8.53,9.85h162.13c5.12,0,8.53-3.94,8.53-9.85c0-5.91-3.41-9.85-8.53-9.85H271.93
				                    C266.81,294.38,263.4,298.32,263.4,304.23z" fill={props.fill} />
                                <path d="M434.07,353.46h-76.8c-5.12,0-8.53,3.94-8.53,9.85c0,5.91,3.41,9.85,8.53,9.85h76.8c5.12,0,8.53-3.94,8.53-9.85
				                    C442.6,357.4,439.19,353.46,434.07,353.46z" fill={props.fill} />
                                <path d="M271.93,373.15h51.2c5.12,0,8.53-3.94,8.53-9.85s-3.41-9.85-8.53-9.85h-51.2c-5.12,0-8.53,3.94-8.53,9.85
				                    C263.4,369.22,266.81,373.15,271.93,373.15z" fill={props.fill} />
                                <path d="M434.07,412.54H391.4c-5.12,0-8.53,3.94-8.53,9.85c0,5.91,3.41,9.85,8.53,9.85h42.67c5.12,0,8.53-3.94,8.53-9.85
				                    C442.6,416.48,439.19,412.54,434.07,412.54z" fill={props.fill} />
                                <path d="M357.27,412.54h-85.33c-5.12,0-8.53,3.94-8.53,9.85c0,5.91,3.41,9.85,8.53,9.85h85.33c5.12,0,8.53-3.94,8.53-9.85
				                    C365.8,416.48,362.39,412.54,357.27,412.54z" fill={props.fill} />
                                <path d="M433.86,77.77c7.71,12.02,20.12,19.69,34.34,19.69c23.89,0,42.67-21.66,42.67-49.23S492.09-1,468.2-1
                                    c-14.22,0-26.62,7.67-34.34,19.69H7.4c-5.12,0-8.53,3.94-8.53,9.85v39.38c0,5.91,3.41,9.85,8.53,9.85h85.33v39.38H33
                                    c-18.77,0-34.13,17.72-34.13,39.39v315.08C-1.13,493.28,14.23,511,33,511h409.6c18.77,0,34.13-17.72,34.13-39.38V156.54
                                    c0-21.66-15.36-39.38-34.13-39.38h-59.73V77.77H433.86z M468.2,18.69c14.51,0,25.6,12.8,25.6,29.54s-11.09,29.54-25.6,29.54
                                    c-9.14,0-16.93-5.09-21.44-13.04c-0.2-0.6-0.43-1.18-0.75-1.73c-1.23-2.37-2.06-4.74-2.6-7.12c-0.22-0.99-0.39-2-0.52-3.03
                                    c0-0.02-0.01-0.04-0.01-0.06c-0.06-0.47-0.11-0.93-0.15-1.41c-0.01-0.11-0.02-0.22-0.02-0.33c-0.03-0.4-0.06-0.8-0.08-1.2
                                    c-0.02-0.54-0.04-1.09-0.04-1.63c0-0.51,0.01-1.01,0.03-1.51c0-0.03,0-0.05,0-0.08c0.02-0.47,0.05-0.94,0.09-1.41
                                    c0.01-0.11,0.02-0.21,0.03-0.32c0.02-0.2,0.04-0.4,0.06-0.61c0.42-3.62,1.55-7.23,4.05-10.84c0.67-1.16,1.07-2.48,1.21-3.83
                                    C452.7,22.9,459.89,18.69,468.2,18.69z M15.93,38.39h410.42c-0.02,0.1-0.03,0.2-0.05,0.3c-0.77,2.92-0.78,6.69-0.78,9.55
                                    c0,2.86,0.01,6.63,0.78,9.55c0.02,0.1,0.03,0.2,0.05,0.3H15.93V38.39z M442.6,136.85c9.39,0,17.07,8.86,17.07,19.69v315.08
                                    c0,10.83-7.68,19.69-17.07,19.69H33c-9.39,0-17.07-8.86-17.07-19.69V156.54c0-10.83,7.68-19.69,17.07-19.69h59.73v20.95
                                    c-14.68,4.4-25.6,19.86-25.6,38.13c0,21.66,15.36,39.38,34.13,39.38s34.13-17.72,34.13-39.38c0-18.26-10.92-33.73-25.6-38.13
                                    v-20.95h256v20.95c-14.68,4.4-25.6,19.86-25.6,38.13c0,21.66,15.36,39.38,34.13,39.38s34.13-17.72,34.13-39.38
                                    c0-18.27-10.92-33.73-25.6-38.13v-20.95H442.6z M101.27,195.92c5.12,0,8.53-3.94,8.53-9.85v-7.17c5.09,3.42,8.53,9.77,8.53,17.01
                                    c0,10.83-7.68,19.69-17.07,19.69s-17.07-8.86-17.07-19.69c0-7.25,3.45-13.59,8.53-17.01v7.17
                                    C92.73,191.99,96.15,195.92,101.27,195.92z M374.33,195.92c5.12,0,8.53-3.94,8.53-9.85v-7.17c5.09,3.42,8.53,9.77,8.53,17.01
                                    c0,10.83-7.68,19.69-17.07,19.69s-17.07-8.86-17.07-19.69c0-7.25,3.45-13.59,8.53-17.01v7.17
                                    C365.8,191.99,369.21,195.92,374.33,195.92z M365.8,117.15h-256V77.77h256V117.15z" fill={props.fill} />
                            </g>
                        </g>
                    </g>
                </svg>
            break;
        case 'legal-document':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{...style}}>
                    <polygon points="436.89,408.79 436.89,21.77 104.09,21.77 104.09,0 458.49,0 458.49,408.79 " fill={props.fill} />
                    <rect x="155.29" y="129.01" width="149.6" height="21.77" fill={props.fill} />
                    <rect x="104.09" y="180.61" width="252" height="21.77" fill={props.fill} />
                    <rect x="104.09" y="232.21" width="175.2" height="21.77" fill={props.fill} />
                    <path d="M306.89,512c-3.64,0-7.29-0.87-10.56-2.51l-15.04-7.58l-15.05,7.58c-3.26,1.64-6.9,2.51-10.55,2.51
                        c-4.39,0-8.68-1.23-12.4-3.55c-7.01-4.37-11.2-11.93-11.2-20.24V460.4H52.89V51.6h354.4V460.4h-76.8v27.82
                        c0,8.3-4.18,15.87-11.19,20.23C315.57,510.77,311.28,512,306.89,512z M253.69,491.48l27.6-13.91l27.6,13.91V454.8l-2.67,0.96
                        c-8.69,3.12-16.85,4.64-24.93,4.64c-8.08,0-16.24-1.52-24.93-4.64l-2.67-0.96V491.48z M281.29,309.62c41.25,0,74.8,33.82,74.8,75.39
                        c0,18.38-6.91,36.22-19.46,50.25l-3.01,3.37h52.07V73.37H74.49v365.25h154.47l-3.01-3.37c-12.55-14.03-19.46-31.88-19.46-50.25
                        C206.49,343.44,240.04,309.62,281.29,309.62z M281.29,331.39c-29.33,0-53.2,24.05-53.2,53.62s23.87,53.62,53.2,53.62
                        c29.33,0,53.2-24.05,53.2-53.62S310.62,331.39,281.29,331.39z" fill={props.fill} />
                </svg>
            break;
        case 'tax-accounting':
            icon =
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{...style}}>
                    <g>
                        <path d="M311.22,508.92l-17.06-9.79L8.74,336.2l20.9-12l286.82,163.79l162.02-93.01l21.01,12.01L316.67,512L311.22,508.92z
	                        M310.79,451.52l-17.07-9.69L8.74,279.22l20.9-12l286.61,163.47l162.66-93.32l21.11,12.01L316.36,454.7L310.79,451.52z
                             M311.22,394.85l-17.06-9.79L8.74,222.13l20.9-12l286.82,163.79l162.02-93.01l21.01,12L316.67,397.93L311.22,394.85z M310.79,337.45
                            l-17.07-9.69L8.74,165.15l20.9-12l286.61,163.47l162.66-93.32l21.11,12.01L316.36,340.63L310.79,337.45z M314.49,280.91
                            c-2.59,0-5.05-0.57-6.91-1.61L10.53,109.93C8.9,109,8,107.81,8,106.59c0-1.22,0.9-2.41,2.53-3.35L187.6,1.6
                            c1.85-1.03,4.32-1.6,6.94-1.6c2.62,0,5.05,0.57,6.86,1.6l297.06,169.37c1.63,0.93,2.54,2.13,2.54,3.36c0.01,1.22-0.86,2.4-2.45,3.34
                            L321.39,279.31C319.54,280.33,317.09,280.91,314.49,280.91z M310.77,231.3c10.13,0,19.66,2.25,26.83,6.34
                            c2.27,1.22,4.2,2.62,5.76,4.16l0.56,0.55l86.01-49.52l-2.09-0.71c-3.07-1.05-5.45-2.09-7.49-3.28c-7.03-3.96-10.9-9.19-10.93-14.73
                            c-0.02-5.51,3.78-10.74,10.71-14.72c2.18-1.17,4.57-2.22,7.3-3.19l2.06-0.73L226.19,39.52l-0.56,0.5c-1.22,1.1-2.66,2.12-4.29,3.02
                            c-7.13,4.1-16.63,6.35-26.76,6.35c-10.13,0-19.66-2.25-26.83-6.34c-1.76-1.08-3.07-2-4.23-2.96l-0.55-0.45L78.01,88.42l1.9,0.77
                            c1.66,0.67,3.33,1.48,5.11,2.47c7.01,3.95,10.88,9.17,10.91,14.71c0.02,5.51-3.78,10.74-10.71,14.72c-1.54,0.95-3.27,1.79-5.3,2.57
                            l-1.97,0.76L280.5,240.09l0.55-0.42c0.96-0.73,1.86-1.42,2.96-2.02C291.14,233.55,300.64,231.3,310.77,231.3z M351.56,205.25
                            c-1.18,0-2.28-0.26-3.11-0.73l-22.93-13.06c-0.64-0.37-1.01-0.82-1.01-1.24c0-0.42,0.37-0.87,1.01-1.24l13.12-7.48
                            c0.83-0.47,1.94-0.73,3.13-0.73c1.2,0,2.34,0.26,3.22,0.74l22.92,13.05c0.58,0.35,0.92,0.8,0.92,1.22
                            c-0.01,0.43-0.38,0.89-1.03,1.26l-13.12,7.48C353.84,204.99,352.74,205.25,351.56,205.25z M254.73,187.19
                            c-22.16,0-42.97-4.92-58.6-13.85c-15.54-8.85-24.12-20.56-24.14-32.98c-0.03-12.34,8.4-23.99,23.72-32.8
                            c15.58-8.93,36.35-13.84,58.5-13.84c22.14,0,42.97,4.92,58.66,13.85c15.53,8.87,24.08,20.6,24.09,33.02
                            c0.01,12.34-8.43,23.97-23.77,32.76C297.65,182.27,276.89,187.19,254.73,187.19z M221.15,151.09h24.06l53.61,0.11v-15.17l-52.59-0.1
                            v-12.64l-25.09-0.12V151.09z M167.24,100.13c-1.18,0-2.29-0.26-3.11-0.73l-22.93-13.06c-0.64-0.37-1.01-0.82-1.01-1.24
                            c0-0.42,0.37-0.87,1.01-1.24l13.12-7.48c0.83-0.47,1.94-0.73,3.13-0.73c1.2,0,2.34,0.26,3.22,0.74l22.81,13.05
                            c0.64,0.37,1.01,0.82,1.01,1.24c0,0.42-0.37,0.87-1.01,1.24l-13.12,7.48C169.53,99.87,168.42,100.13,167.24,100.13z" fill={props.fill} />
                    </g>
                </svg>
            break;
        case 'uk-eu-relationship':
            icon =
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 125" style={{...style}}>
                    <switch>
                        <foreignObject requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/" x="0" y="0" width="1" height="1"/>
                        <g>
                            <g>
                                <path d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4
                                    c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z
                                     M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z" fill={props.fill} />
                                <path d="M5268.4,2410.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5268.4z" fill={props.fill} />
                                <path d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z" fill={props.fill} />
                                <path d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z" fill={props.fill} />
                            </g>
                            <g>
                                <path d="M86.9,61c-1.3,0-2.3,1-2.3,2.3v18c0,4.1-3.3,7.4-7.4,7.4H14.6c-4.1,0-7.4-3.3-7.4-7.4V18.7c0-4.1,3.3-7.4,7.4-7.4h62.6
                                    c4.1,0,7.4,3.3,7.4,7.4v18c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3v-18c0-6.7-5.4-12.1-12.1-12.1H14.6C7.9,6.6,2.5,12,2.5,18.7v62.6
                                    c0,6.7,5.4,12.1,12.1,12.1h62.6c6.7,0,12.1-5.4,12.1-12.1v-18C89.3,62,88.2,61,86.9,61z" fill={props.fill} />
                                <path d="M46.6,41.9c1.3-0.9,3-0.6,4.4,0.6c0.4,0.4,1,0.6,1.6,0.6c0.6,0,1.2-0.3,1.6-0.7c0.4-0.5,0.6-1,0.6-1.6
                                    c0-0.6-0.3-1.2-0.7-1.6c-2.9-2.7-6.8-3.1-9.9-1c-3.2,2.1-4.4,6-3.1,9.9c0.1,0.1,0.1,0.3,0.1,0.4l0.1,0.5h-2.5
                                    c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h2.7l0,0.3c-0.6,3.8-3,6.3-3.1,6.4c-0.7,0.7-0.9,1.8-0.4,2.7c0.5,0.9,1.5,1.3,2.5,1.1
                                    c0.8-0.2,2.4-0.5,3.7-0.5c0.7,0,1.3,0.1,1.8,0.2c0.8,0.2,1.7,0.4,2.7,0.4c2.1,0,3.9-0.5,4.1-0.6c1.2-0.3,1.9-1.6,1.6-2.8
                                    c-0.2-0.6-0.5-1.1-1.1-1.4c-0.5-0.3-1.1-0.4-1.7-0.2c-0.6,0.2-1.8,0.4-3,0.4c-0.5,0-0.9-0.1-1.3-0.2c-0.7-0.2-1.5-0.4-2.5-0.4
                                    l-0.4,0l0.2-0.3c0.7-1.6,1.2-3.3,1.4-4.9v-0.2h4.1c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8H46l-0.1-0.2
                                    c-0.1-0.8-0.3-1.5-0.5-2.1C44.6,44.3,45.6,42.6,46.6,41.9z" fill={props.fill} />
                                <path d="M50.4,22.3c0.6-0.5,0.3-1.4-0.5-1.4l-1.9-0.2c-0.3,0-0.6-0.2-0.7-0.5l-0.7-1.8c-0.3-0.7-1.2-0.7-1.5,0l-0.7,1.8
                                    c-0.1,0.3-0.4,0.5-0.7,0.5l-1.9,0.2c-0.7,0.1-1,1-0.5,1.4l1.5,1.2c0.2,0.2,0.3,0.5,0.3,0.8l-0.4,1.9c-0.2,0.7,0.6,1.3,1.2,0.9
                                    l1.6-1c0.3-0.2,0.6-0.2,0.9,0l1.6,1c0.6,0.4,1.4-0.2,1.2-0.9l-0.4-1.9c-0.1-0.3,0-0.6,0.3-0.8L50.4,22.3z" fill={props.fill} />
                                <path d="M27.8,26.8c-0.6,0.4-0.4,1.4,0.3,1.5l1.9,0.4c0.3,0.1,0.5,0.3,0.6,0.6l0.5,1.8c0.2,0.7,1.2,0.8,1.5,0.2l0.9-1.7
                                    c0.1-0.3,0.4-0.4,0.7-0.4l1.9,0c0.7,0,1.1-0.9,0.6-1.4l-1.3-1.4c-0.2-0.2-0.3-0.5-0.2-0.8l0.6-1.8c0.2-0.7-0.5-1.3-1.1-1
                                    l-1.7,0.8c-0.3,0.1-0.6,0.1-0.9-0.1l-1.5-1.2c-0.6-0.4-1.4,0-1.3,0.8l0.2,1.9c0,0.3-0.1,0.6-0.3,0.8L27.8,26.8z" fill={props.fill} />
                                <path d="M27,35.3l-1.8-0.5c-0.3-0.1-0.5-0.3-0.6-0.6l-0.4-1.9c-0.1-0.7-1.1-0.9-1.5-0.3l-1.1,1.6c-0.2,0.3-0.5,0.4-0.8,0.3
                                    l-1.9-0.2c-0.7-0.1-1.2,0.7-0.8,1.3l1.2,1.5c0.2,0.2,0.2,0.6,0.1,0.9L18.6,39c-0.3,0.7,0.3,1.4,1,1.1l1.8-0.6
                                    c0.3-0.1,0.6,0,0.8,0.2l1.4,1.3c0.5,0.5,1.4,0.1,1.4-0.6l0-1.9c0-0.3,0.2-0.6,0.4-0.7l1.7-0.9C27.8,36.4,27.7,35.5,27,35.3z" fill={props.fill} />
                                <path d="M16.8,45.9l-0.2,1.9c0,0.3-0.2,0.6-0.5,0.7l-1.8,0.7c-0.7,0.3-0.7,1.2,0,1.5l1.8,0.7c0.3,0.1,0.5,0.4,0.5,0.7l0.2,1.9
                                    c0.1,0.7,1,1,1.4,0.5l1.2-1.5c0.2-0.2,0.5-0.3,0.8-0.3l1.9,0.4c0.7,0.2,1.3-0.6,0.9-1.2l-1-1.6c-0.2-0.3-0.2-0.6,0-0.9l1-1.6
                                    c0.4-0.6-0.2-1.4-0.9-1.2l-1.9,0.4c-0.3,0.1-0.6,0-0.8-0.3l-1.2-1.5C17.7,44.9,16.8,45.2,16.8,45.9z" fill={props.fill} />
                                <path d="M18.9,66.4l1.9-0.2c0.3,0,0.6,0.1,0.8,0.3l1.1,1.6c0.4,0.6,1.4,0.4,1.5-0.3l0.4-1.9c0.1-0.3,0.3-0.5,0.6-0.6l1.8-0.5
                                    c0.7-0.2,0.8-1.2,0.2-1.5l-1.7-0.9c-0.3-0.1-0.4-0.4-0.4-0.7l0-1.9c0-0.7-0.9-1.1-1.4-0.6l-1.4,1.3c-0.2,0.2-0.5,0.3-0.8,0.2
                                    l-1.8-0.6c-0.7-0.2-1.3,0.5-1,1.1l0.8,1.7c0.1,0.3,0.1,0.6-0.1,0.9l-1.2,1.5C17.7,65.7,18.2,66.5,18.9,66.4z" fill={props.fill} />
                                <path d="M36.9,72.2c0.5-0.5,0.1-1.4-0.6-1.4l-1.9,0c-0.3,0-0.6-0.2-0.7-0.4l-0.9-1.7c-0.4-0.6-1.3-0.5-1.5,0.2l-0.5,1.8
                                    c-0.1,0.3-0.3,0.5-0.6,0.6l-1.9,0.4c-0.7,0.1-0.9,1.1-0.3,1.5l1.6,1.1c0.3,0.2,0.4,0.5,0.3,0.8l-0.2,1.9
                                    c-0.1,0.7,0.7,1.2,1.3,0.8l1.5-1.2c0.2-0.2,0.6-0.2,0.9-0.1l1.7,0.8c0.7,0.3,1.4-0.3,1.1-1l-0.6-1.8c-0.1-0.3,0-0.6,0.2-0.8
                                    L36.9,72.2z" fill={props.fill} />
                                <path d="M41.3,77.7c-0.6,0.5-0.3,1.4,0.5,1.4l1.9,0.2c0.3,0,0.6,0.2,0.7,0.5l0.7,1.8c0.3,0.7,1.2,0.7,1.5,0l0.7-1.8
                                    c0.1-0.3,0.4-0.5,0.7-0.5l1.9-0.2c0.7-0.1,1-1,0.5-1.4L49,76.4c-0.2-0.2-0.3-0.5-0.3-0.8l0.4-1.9c0.2-0.7-0.6-1.3-1.2-0.9l-1.6,1
                                    c-0.3,0.2-0.6,0.2-0.9,0l-1.6-1c-0.6-0.4-1.4,0.2-1.2,0.9l0.4,1.9c0.1,0.3,0,0.6-0.3,0.8L41.3,77.7z" fill={props.fill} />
                                <path d="M64,73.2c0.6-0.4,0.4-1.4-0.3-1.5l-1.9-0.4c-0.3-0.1-0.5-0.3-0.6-0.6l-0.5-1.8c-0.2-0.7-1.2-0.8-1.5-0.2l-0.9,1.7
                                    c-0.1,0.3-0.4,0.4-0.7,0.4l-1.9,0c-0.7,0-1.1,0.9-0.6,1.4l1.3,1.4c0.2,0.2,0.3,0.5,0.2,0.8l-0.6,1.8c-0.2,0.7,0.5,1.3,1.1,1
                                    l1.7-0.8c0.3-0.1,0.6-0.1,0.9,0.1l1.5,1.2c0.6,0.4,1.4,0,1.3-0.8L62.1,75c0-0.3,0.1-0.6,0.3-0.8L64,73.2z" fill={props.fill} />
                                <path d="M64.8,64.7l1.8,0.5c0.3,0.1,0.5,0.3,0.6,0.6l0.4,1.9c0.1,0.7,1.1,0.9,1.5,0.3l1.1-1.6c0.2-0.3,0.5-0.4,0.8-0.3l1.9,0.2
                                    c0.7,0.1,1.2-0.7,0.8-1.3l-1.2-1.5c-0.2-0.2-0.2-0.6-0.1-0.9l0.8-1.7c0.3-0.7-0.3-1.4-1-1.1l-1.8,0.6c-0.3,0.1-0.6,0-0.8-0.2
                                    L68.1,59c-0.5-0.5-1.4-0.1-1.4,0.6l0,1.9c0,0.3-0.2,0.6-0.4,0.7l-1.7,0.9C64,63.6,64.1,64.5,64.8,64.7z" fill={props.fill} />
                                <path d="M97,49.2l-1.8-0.7c-0.3-0.1-0.5-0.4-0.5-0.7l-0.2-1.9c-0.1-0.7-1-1-1.4-0.5l-1.2,1.5c-0.2,0.2-0.5,0.3-0.8,0.3l-1.9-0.4
                                    c-0.7-0.2-1.3,0.6-0.9,1.2l1,1.6c0.2,0.3,0.2,0.6,0,0.9l-1,1.6c-0.4,0.6,0.2,1.4,0.9,1.2l1.9-0.4c0.3-0.1,0.6,0,0.8,0.3l1.2,1.5
                                    c0.5,0.6,1.4,0.3,1.4-0.5l0.2-1.9c0-0.3,0.2-0.6,0.5-0.7l1.8-0.7C97.7,50.5,97.7,49.5,97,49.2z" fill={props.fill} />
                                <path d="M73.6,34.9c0.4-0.6,0-1.4-0.8-1.3l-1.9,0.2c-0.3,0-0.6-0.1-0.8-0.3L69,31.9c-0.4-0.6-1.4-0.4-1.5,0.3l-0.4,1.9
                                    c-0.1,0.3-0.3,0.5-0.6,0.6l-1.8,0.5c-0.7,0.2-0.8,1.2-0.2,1.5l1.7,0.9c0.3,0.1,0.4,0.4,0.4,0.7l0,1.9c0,0.7,0.9,1.1,1.4,0.6
                                    l1.4-1.3c0.2-0.2,0.5-0.3,0.8-0.2l1.8,0.6c0.7,0.2,1.3-0.5,1-1.1l-0.8-1.7c-0.1-0.3-0.1-0.6,0.1-0.9L73.6,34.9z" fill={props.fill} />
                                <path d="M54.9,27.8c-0.5,0.5-0.1,1.4,0.6,1.4l1.9,0c0.3,0,0.6,0.2,0.7,0.4l0.9,1.7c0.4,0.6,1.3,0.5,1.5-0.2l0.5-1.8
                                    c0.1-0.3,0.3-0.5,0.6-0.6l1.9-0.4c0.7-0.1,0.9-1.1,0.3-1.5l-1.6-1.1c-0.3-0.2-0.4-0.5-0.3-0.8l0.2-1.9c0.1-0.7-0.7-1.2-1.3-0.8
                                    l-1.5,1.2c-0.2,0.2-0.6,0.2-0.9,0.1l-1.7-0.8c-0.7-0.3-1.4,0.3-1.1,1l0.6,1.8c0.1,0.3,0,0.6-0.2,0.8L54.9,27.8z" fill={props.fill} />
                                <path d="M76.5,56c0.4,0.4,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5l4.7-4.7c0.7-0.7,0.7-1.9,0-2.6L79.1,44c-0.7-0.7-1.9-0.7-2.6,0
                                    c-0.7,0.7-0.7,1.9,0,2.6l1.6,1.6H60.9c-1,0-1.9,0.8-1.9,1.9s0.8,1.9,1.9,1.9h17.2l-1.6,1.6C75.8,54.2,75.8,55.3,76.5,56z" fill={props.fill} />
                            </g>
                        </g>
                    </switch>
                </svg>
            break;
        case 'property-conveyancing':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{...style}}>
                    <g>
                        <g>
                            <path d="M508.16,240.53L262.36,2.78c-3.83-3.71-9.52-3.71-13.36,0L3.21,240.53c-3.22,3.11-4.37,8.13-2.88,12.59
                                s5.3,7.41,9.56,7.41h61.79v240.09c0,6.29,4.57,11.39,10.2,11.39h347.61c5.63,0,10.2-5.1,10.2-11.39V260.53h61.79
                                c4.26,0,8.07-2.95,9.56-7.41C512.53,248.66,511.38,243.64,508.16,240.53z M429.49,237.75c-5.63,0-10.2,5.1-10.2,11.39v240.09
                                H92.08V249.14c0-6.29-4.57-11.39-10.2-11.39H37.23l218.45-211.3l218.45,211.3H429.49z" fill={props.fill} />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M414.29,203.34l-66.7-65.47c-4.23-4.15-10.67-3.69-14.39,1.03s-3.3,11.92,0.93,16.07l66.7,65.47
			                    c1.94,1.9,4.34,2.83,6.73,2.83c2.83,0,5.65-1.31,7.66-3.87C418.94,214.69,418.52,207.49,414.29,203.34z" fill={props.fill} />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M323.99,114.71l-3.08-3.02c-4.23-4.16-10.67-3.69-14.39,1.03c-3.72,4.72-3.3,11.92,0.92,16.07l3.08,3.02
			                    c1.94,1.9,4.34,2.84,6.73,2.84c2.83,0,5.65-1.31,7.66-3.87C328.64,126.05,328.22,118.86,323.99,114.71z" fill={props.fill} />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M376.11,391.23L269.75,272.47c4.89-24.17-1.52-49.35-17.45-67.13c-12.26-13.69-28.56-21.23-45.9-21.23
                                c-17.34,0-33.64,7.54-45.9,21.23c-25.31,28.26-25.31,74.24,0,102.5c15.93,17.78,38.48,24.94,60.12,19.48l7.08,7.91
                                c2.02,2.26,4.83,3.46,7.69,3.32l0.76,3.66c0.43,2.08,1.37,3.98,2.73,5.49l9.97,11.13c1.91,2.13,4.51,3.33,7.21,3.33h2.96v3.31
                                c0,3.02,1.08,5.92,2.99,8.05l7.78,8.68c1.91,2.13,4.51,3.33,7.21,3.33h12.93v14.44c0,3.02,1.08,5.92,2.99,8.05l15.95,17.81
                                c1.91,2.13,4.51,3.33,7.21,3.33h5.89v6.57c0,3.02,1.08,5.92,2.99,8.05l14.43,16.11c1.91,2.13,4.51,3.33,7.21,3.33
                                s5.3-1.2,7.21-3.33l22.3-24.9c1.91-2.14,2.99-5.03,2.99-8.05v-27.68C379.1,396.26,378.02,393.36,376.11,391.23z M358.7,422.25
                                l-12.1,13.51l-4.23-4.72h0V417.8c0-6.29-4.57-11.39-10.2-11.39h-11.86l-9.98-11.14v-21.11c0-6.29-4.57-11.39-10.2-11.39h-18.91
                                l-1.8-2.01v-9.98c0-6.29-4.57-11.39-10.2-11.39h-8.94l-4.91-5.49l-3.53-17.08c-0.83-4.03-3.56-7.24-7.13-8.38
                                c-3.46-1.11-7.18-0.1-9.79,2.64l-4.07-4.55c-2.73-3.04-6.76-4.11-10.42-2.76c-16.1,5.95-33.53,1.34-45.51-12.03
                                c-17.36-19.38-17.36-50.91,0-70.29c8.41-9.39,19.59-14.56,31.48-14.56c11.89,0,23.07,5.17,31.48,14.56
                                c11.98,13.37,16.11,32.84,10.78,50.81c-1.21,4.09-0.26,8.59,2.47,11.63L358.7,404L358.7,422.25L358.7,422.25z" fill={props.fill} />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M208.33,242.25l-5.61-6.26c-3.98-4.45-10.44-4.45-14.43,0c-3.98,4.45-3.98,11.66,0,16.11l5.61,6.26
			                    c1.99,2.22,4.6,3.33,7.21,3.33s5.22-1.11,7.21-3.33C212.31,253.91,212.31,246.7,208.33,242.25z" fill={props.fill} />
                        </g>
                    </g>
                </svg>
            break;
        case 'mortgage-service':
            icon =
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.003 512.003" style={{...style}}>
                    <path d="M11.85,512c-6.53,0-11.85-5.3-11.85-11.82s5.31-11.82,11.85-11.82h39.55V281.15H24.7c-10.02,0-18.98-5.97-22.81-15.22
                        c-3.84-9.25-1.74-19.79,5.35-26.87l82.71-82.55V51.3c0-13.59,11.08-24.65,24.7-24.65h25.7c13.62,0,24.7,11.06,24.7,24.65v30.27
                        l73.49-73.34C243.2,3.56,249.4,1,256,1c6.6,0,12.8,2.56,17.47,7.21l231.29,230.85c7.08,7.08,9.18,17.62,5.35,26.87
                        c-3.84,9.24-12.8,15.22-22.82,15.22h-26.7v207.21h39.55c6.53,0,11.85,5.3,11.85,11.82S506.67,512,500.14,512H11.85z M75.1,488.35
                        h361.8V257.5h52.82L256,24.23L22.28,257.5H75.1V488.35z M113.65,132.86l27.71-27.65V50.3h-27.71V132.86z" fill={props.fill} />
                    <polygon points="145.54,426.98 349.71,223.2 366.46,239.92 162.29,443.7 " fill={props.fill} />
                    <path d="M191.75,319.62c-27.79,0-50.39-22.56-50.39-50.3c0-27.73,22.61-50.3,50.39-50.3c27.79,0,50.39,22.56,50.39,50.3
                        C242.14,297.06,219.54,319.62,191.75,319.62z M191.75,242.67c-14.72,0-26.7,11.96-26.7,26.65c0,14.7,11.98,26.65,26.7,26.65
                        c14.72,0,26.7-11.96,26.7-26.65C218.45,254.63,206.47,242.67,191.75,242.67z" fill={props.fill} />
                    <path d="M320.25,447.87c-27.79,0-50.39-22.56-50.39-50.3c0-27.73,22.61-50.3,50.39-50.3c27.79,0,50.39,22.56,50.39,50.3
                        C370.64,425.31,348.03,447.87,320.25,447.87z M320.25,370.92c-14.72,0-26.7,11.96-26.7,26.65c0,14.7,11.98,26.65,26.7,26.65
                        c14.72,0,26.7-11.96,26.7-26.65C346.95,382.88,334.97,370.92,320.25,370.92z" fill={props.fill} />
                </svg>
            break;
        case 'money-transfer':
            icon =
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <g>
                        <g>
                            <g>
                                <path d="m473.6,256.6c0-99.3-67.1-185.9-163.3-210.6-9.2-2.4-18.6,3.2-20.9,12.4-2.4,9.2 3.2,18.6 12.4,20.9 80.9,20.7 137.4,93.7 137.4,177.3 0,73.7-43.9,139-109.6,167.7l15.9-37c3.7-8.7-0.3-18.8-9-22.6-8.7-3.7-18.8,0.3-22.6,9l-31,72.2c-3.7,8.7 0.3,18.8 9,22.6l72.2,31c10.8,3.8 19.8-2.5 22.6-9 3.7-8.7-0.3-18.8-9-22.6l-31.4-13.5c76.5-34.6 127.3-111.4 127.3-197.8z" fill={props.fill} />
                                <path d="m210.4,433.9c-80.9-20.8-137.4-93.7-137.4-177.3 0-72.7 42.7-137.2 106.9-166.5l-14.9,34.6c-3.7,8.7 0.1,19.2 9,22.6 10.4,3.9 19.2-0.8 22.6-9l31-72.3c3-7 2.9-17.5-9-22.6l-72.2-31c-8.7-3.7-18.8,0.3-22.6,9-3.7,8.7 0.3,18.8 9,22.6l33.6,14.4c-76.8,34.7-127.8,111.6-127.8,198.2 7.10543e-15,99.3 67.2,185.8 163.3,210.6 11.6,3 18.9-4.6 20.9-12.4 2.3-9.2-3.2-18.5-12.4-20.9z" fill={props.fill} />
                                <path d="m296.6,321.4c8.8-9.2 13.2-20.8 13.2-34.6 0-6.3-1.1-12.1-3.2-17.5-2.1-5.4-5.1-10-9-14-3.9-3.9-9-7.2-15.2-10-3.3-1.4-9.9-3.4-20-5.9v-57.4c6.5,1.3 11.6,4.1 15.4,8.3 3.7,4.2 6.2,10.2 7.4,18l20.6-3.1c-1.8-12.6-7-22.6-15.7-29.8-6.8-5.7-16-9.1-27.6-10.2v-9.5h-11.8v9.5c-13.2,1.3-23.2,5-29.9,10.9-10.1,8.8-15.1,20.3-15.1,34.5 0,8 1.7,15.1 5.2,21.4 3.4,6.3 8.3,11.2 14.7,14.7 8.6,4.8 17,8 25.2,9.5v63.5c-7.8-0.8-14.6-4.8-20.2-11.9-3.9-5-6.7-12.9-8.2-23.6l-20.1,3.8c0.7,10.3 3.4,19.1 7.9,26.3 4.5,7.2 9.9,12.4 16.2,15.6 6.2,3.2 14.4,5.4 24.3,6.7v19.9h11.8v-20.2c14-0.7 25.4-5.7 34.1-14.9zm-45.8-84.5c-9.1-2.7-15.5-6.4-19.3-11-3.8-4.6-5.7-10.3-5.7-17.1 0-6.9 2.2-12.8 6.7-17.8 4.5-5 10.6-8.1 18.3-9.3v55.2zm30.9,72.6c-5.1,5.8-11.5,9.1-19.2,10.1v-60.7c10.7,3.5 17.9,7.4 21.5,11.8 3.6,4.4 5.4,10.2 5.4,17.5-0.1,8.4-2.6,15.6-7.7,21.3z"  fill={props.fill}/>
                            </g>
                        </g>
                    </g>
                </svg>
            break;
        default:
            icon =
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <g>
                        <g id="question_x5F_mark">
                            <g>
                                <path d="M17.6,30c0,1.102-0.895,2-2,2s-2-0.898-2-2c0-1.109,0.895-2,2-2S17.6,28.891,17.6,30z" fill={props.fill} />
                                <path d="M15.676,25.977c-1.336,0-2.59-0.523-3.535-1.469c-0.945-1.105-1.465-2.359-1.465-3.695
                                    s0.52-2.59,1.465-3.371l6.688-6.688C19.584,9.996,20,8.992,20,7.926c0-1.07-0.416-2.074-1.172-2.828
                                    c-1.559-1.559-4.096-1.562-5.654,0C12.416,5.852,12,6.855,12,7.926H8c0-2.137,0.834-4.148,2.348-5.66
                                    c3.02-3.023,8.285-3.02,11.309,0.004C23.168,3.777,24,5.785,24,7.926c0,2.137-0.832,4.145-2.344,5.656l-6.688,6.523
                                    c-0.389,0.391-0.389,1.023,0,1.414c0.391,0.391,1.023,0.391,1.414,0c0.254-0.258,0.293-0.555,0.293-0.711h4
                                    c0,1.336-0.52,2.594-1.465,3.699C18.266,25.453,17.012,25.977,15.676,25.977L15.676,25.977z" fill={props.fill} />
                            </g>
                        </g>
                    </g>
                </svg>
            break;
    }

    return (
        <>
            {icon}
        </>
    )
}

export default Icon;